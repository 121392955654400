import SagaBuilder from '../../sagas/SagaBuilder';
import forEach from 'lodash/forEach';

const genSaga = (T, api = {}, actions) => {
  const sagaBuilder = new SagaBuilder();

  const { get, findOne, create, update, delete: deleteAPI } = api;

  if (get) {
    sagaBuilder.add({
      type: T.API_GET,
      apiService: get,
      successFunc: actions.storeAll,
      errorFunc: actions.fail
    });
  }
  if (findOne) {
    sagaBuilder.add({
      type: T.API_FIND_ONE,
      apiService: findOne,
      successFunc: actions.storeObj,
      errorFunc: actions.fail
    });
  }
  if (create) {
    sagaBuilder.add({
      type: T.API_CREATE,
      apiService: create,
      successFunc: actions.storeObj,
      errorFunc: actions.fail
    });
  }
  if (update) {
    sagaBuilder.add({
      type: T.API_UPDATE,
      apiService: update,
      successFunc: actions.updateObj,
      errorFunc: actions.fail
    });
  }
  if (deleteAPI) {
    sagaBuilder.add({
      type: T.API_DELETE,
      apiService: deleteAPI,
      successFunc: actions.removeObj,
      errorFunc: actions.fail
    });
  }
  return sagaBuilder.build();
};

export const genCustomSagas = (sagas = [], initSagas) => {
  const sagaBuilder = new SagaBuilder(initSagas);
  forEach(sagas, saga => sagaBuilder.add(saga));
  return sagaBuilder.build();
};

export default genSaga;
