import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PanelTitle from "../../../components/PanelTitle";
import PopularPost from "../../../components/PopularPost";
import { RoutePaths, CategoryPks } from "../../../constants";
import { makeGetPostsByCatPk } from "../../../model/posts/selectors";

const styles = {
  root: {
    marginTop: 10
  },
  wrapper: {
    marginBottom: 12,
    boxShadow: '0 1px 2px rgba(0,0,0,.05)'
  },
  popularPost: {
    overflowY: 'auto',
    border: '1px solid #3498db',
    borderTop: 0,
    padding: 5,
    background: '#ffffff'
  },
  divider: {
    width: '100%'
  },
  corner: {
    width: 30,
    '&:after': {
      borderWidth: '28px 18px'
    }
  },
}

class Activity extends PureComponent {
  render() {
    const { classes, posts } = this.props;
    const len = posts ? posts.length - 1 : 0;
    return (
      <Grid container className={classes.root}>
        <PanelTitle title="HOẠT ĐỘNG" classes={{ corner: classes.corner }} />
        <Grid container className={classes.popularPost}>
          {posts.map((post, index) => (
            <Fragment key={post.id}>
              <PopularPost post={post} path={RoutePaths.HOAT_DONG} />
              {index < len && <Divider className={classes.divider} />}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    );
  }
}

Activity.defaultProps = {
  posts: []
}

Activity.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  posts: PropTypes.arrayOf(Object)
}

const makeMapStateToProps = () => {
  const getPosts = makeGetPostsByCatPk();
  const mapStateToProps = (state, props) => ({
    posts: getPosts(state, { ...props, id: CategoryPks["hoat-dong"] })
  });
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(withStyles(styles)(Activity));