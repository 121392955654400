import store from "../store";
import tagsAction from "../model/tags/actions";
import { getTags } from "../model/tags/services";

const tagsTask = () =>
  getTags().then(tags => {
    store.dispatch(tagsAction.storeAll(tags));
    return tags;
  });

export default tagsTask;
