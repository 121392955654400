import { isEmpty } from "lodash";
import request from "../../services/request";

export const getPostViews = async () =>
  request
    .get("https://capnuocthotnot.vn/api/v1/post_views/get_post_views")
    .then(res => {
      const { data } = res;
      if (isEmpty(data)) {
        return {};
      }
      return data;
    });

export const updatePostView = async postId =>
  request
    .get(`https://capnuocthotnot.vn/api/v1/post_views/get_post_view/${postId}`)
    .then(res => {
      const { data } = res;
      if (isEmpty(data)) {
        return {};
      }
      return data;
    });

export default {};
