import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EMPTY } from '../constants';

const styles = {
  root: {
    background: '#ffffff',
    borderBottom: '3px solid #3498db',
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    height: 31,
    lineHeight: '34px',
    paddingLeft: 54,
    position: 'relative',
    textTransform: 'uppercase'
  },
  corner: {
    background: '#3498db',
    color: '#ffffff',
    fontSize: 13,
    height: '100%',
    left: 0,
    padding: '0px 0 0 8px',
    position: 'absolute',
    top: 0,
    verticalAlign: 'middle',
    width: 24,
    '&:after': {
      borderColor: 'transparent transparent #3498db',
      borderStyle: 'solid',
      borderWidth: '31px 20px',
      content: "''",
      display: 'block',
      height: 0,
      left: 12,
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: 0,
      zIndex: 0
    }
  },
  title: {
    color: '#3498db',
    fontSize: 14,
    fontWeight: 'bold'
  },
  iconWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 9
  },
  icon: {
    fontSize: 20
  }
}

class PanelTitle extends PureComponent {
  render() {
    const { classes, title, linkTo } = this.props;
    return (
      <div className={classes.root}>
        <span className={classes.corner}>
          <span className={classes.iconWrapper}>
            <ChromeReaderModeIcon className={classes.icon} />
          </span>
        </span>
        {linkTo ? <Link to={linkTo} className={classes.title}>{title}</Link> : <span className={classes.title}>{title}</span>}
      </div>
    )
  }
}

PanelTitle.defaultProps = {
  linkTo: EMPTY
}

PanelTitle.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string
}

export default withStyles(styles)(PanelTitle);