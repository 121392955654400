import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Posts from "./posts";
import PostDetail from "./post_detail";
import { isCategory } from "../../model/categories/selectors";

class Post extends React.Component {
  render() {
    const { location, match, isShowPosts } = this.props;
    if (isShowPosts) {
      return <Posts location={location} match={match} />;
    }
    return <PostDetail location={location} match={match} />;
  }
}

Post.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  isShowPosts: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  isShowPosts: isCategory(state, props),
});

export default connect(mapStateToProps)(Post);
