import { SLASH, EMPTY, SPACE, DASH } from "../constants"
import { regexEscape } from "./string";

export const getFirstPath = location => {
  let path = location || EMPTY;
  if (location instanceof Object) {
    path = location ? location.pathname : EMPTY;
  }
  const paths = path.split(SLASH);
  return paths[1];
}

export const getMiddlePath = location => {
  let path = location || EMPTY;
  if (location instanceof Object) {
    path = location ? location.pathname : EMPTY;
  }
  const paths = path.split(SLASH);
  return paths[paths.length - 2];
}

export const getLastPath = location => {
  let path = location || EMPTY;
  if (location instanceof Object) {
    path = location ? location.pathname : EMPTY;
  }
  const paths = path.split(SLASH);
  return paths[paths.length - 1];
}

export const getRelatePath = location => {
  let path = location || EMPTY;
  if (location instanceof Object) {
    path = location ? location.pathname : EMPTY;
  }
  let paths = path.split(SLASH);
  paths = paths.slice(0, paths.length - 1);
  return paths.join(SLASH);
}

export const getPath = value => regexEscape(value).replace(SPACE, DASH);

export default {};