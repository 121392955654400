import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import Feeback from './Feeback';
import Information from './Information';
import Notify from '../notify'
import styles from './styles';

class Contact extends React.PureComponent {
  render() {
    const { classes, width } = this.props;
    const spacing = isWidthDown('md', width) ? 0 : 4
    return (
      <Grid container>
        <Typography className={classes.well}>
          Để không ngừng nâng cao chất lượng dịch vụ và đáp ứng tốt hơn nữa các yêu cầu của Quý khách, chúng tôi mong muốn nhận được các thông tin phản hồi. Nếu Quý khách có bất kỳ thắc mắc hoặc đóng góp nào, xin vui lòng liên hệ với chúng tôi theo thông tin dưới đây. Chúng tôi sẽ phản hồi lại Quý khách trong thời gian sớm nhất.
        </Typography>
        <Grid item container spacing={spacing}>
          <Grid item lg={6} xs={12}>
            <Information classes={classes} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Feeback classes={classes} />
          </Grid>
        </Grid>
        <Notify />
      </Grid>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string.isRequired,
}

export default withStyles(styles)(withWidth()(Contact));