import forEach from 'lodash/forEach';

const MODEL_PREFIX = 'app/model';

const MODEL_ACTIONS = {
  STORE_ALL: 'store_all',
  STORE_OBJ: 'store_object',
  UPDATE_OBJ: 'update_object',
  REMOVE_OBJ: 'remove_object',

  API_GET: 'api_get',
  API_FIND_ONE: 'api_find_one',
  API_CREATE: 'api_create',
  API_UPDATE: 'api_update',
  API_DELETE: 'api_delete',
  API_FAILED: 'api_failed',

  CLEAR_ERROR: 'clear_error'
};

const genActTypes = appName => {
  const prefix = [MODEL_PREFIX, appName].join('/');

  const types = {};
  forEach(MODEL_ACTIONS, (action, key) => {
    types[key] = `${prefix}/${action}`;
  });

  return types;
};

export const genActType = (appName, action) => {
  const prefix = [MODEL_PREFIX, appName].join('/');
  return `${prefix}/${action}`;
};

export default genActTypes;
