import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Tree, { mutateTree } from '@atlaskit/tree';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { EMPTY } from '../../../constants';

const PADDING_PER_LEVEL = 20;

const PreTextIcon = styled.span`
  display: inline-block;
  justify-content: center;
  cursor: pointer;
`;

const EmptyIcon = styled.span`
  width: 9px;
`;

const TreeItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
`;

const Text = styled.span`
  color: rgba(0, 0, 0, 0.87);
`;

const getIcon = (
  item,
  onExpand,
  onCollapse
) => {
  if (item.children && item.children.length > 0) {
    return item.isExpanded ? (
      <PreTextIcon onClick={() => onCollapse(item.id)}>
        <ExpandMore style={{ verticalAlign: 'sub' }} />
      </PreTextIcon>
    ) : (
        <PreTextIcon onClick={() => onExpand(item.id)}>
          <ChevronRight style={{ verticalAlign: 'sub' }} />
        </PreTextIcon>
      );
  }
  return <EmptyIcon />;
};

class TreeView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tree: { rootId: 'rootId', items: {} }
    };
  }

  componentDidMount() {
    const { treeData } = this.props;
    this.setState({ tree: treeData })
  };

  renderItem = ({ item, onExpand, onCollapse, provided }) => {
    const { onClose } = this.props;
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <TreeItem>
          {getIcon(item, onExpand, onCollapse)}
          <Link to={item.path} onClick={onClose}>
            <Text>{item ? item.name : EMPTY}</Text>
          </Link>
        </TreeItem>
      </div>
    );
  };

  onExpand = (itemId) => {
    const { tree } = this.state;
    this.setState({
      tree: mutateTree(tree, itemId, { isExpanded: true }),
    });
  };

  onCollapse = (itemId) => {
    const { tree } = this.state;
    this.setState({
      tree: mutateTree(tree, itemId, { isExpanded: false }),
    });
  };

  render() {
    const { tree } = this.state;
    return (
      <Tree
        tree={tree}
        renderItem={this.renderItem}
        onExpand={this.onExpand}
        onCollapse={this.onCollapse}
        onDragEnd={this.onDragEnd}
        offsetPerLevel={PADDING_PER_LEVEL}
      />
    );
  }
}

TreeView.propTypes = {
  treeData: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired
}

export default TreeView;