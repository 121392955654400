import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { getPostByCatPk } from "../../model/posts/selectors";
import { EMPTY, CategoryPks } from "../../constants";
import "./styles.scss";

const styles = {
  root: {
    background: "#ffffff",
    borderRadius: 4,
    padding: 5,
  },
  wrapper: {
    background: "#01799e",
    color: "#ffffff",
  },
  content: {
    width: "100%",
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 5,
    paddingBottom: 5,
  },
  link: {
    justifyContent: "center",
    fontWeight: 500,
    marginTop: 5,
  },
  linkText: {
    color: "#337ab7",
    fontSize: 15,
  },
};

class Table extends React.Component {
  render() {
    const { classes, post } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item container className={classes.wrapper}>
          <Typography className={classes.title}>
            {post.title.rendered}
          </Typography>
          <Grid id="table-water-prices" item container>
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: post.content.rendered || EMPTY,
              }}
            ></div>
          </Grid>
        </Grid>
        <Grid item container className={classes.link}>
          <Link
            to="/tin-tuc/tin-hoat-dong-cong-ty/gia-nuoc-sach-khu-vuc-do-thi-nam-2019"
            className={classes.linkText}
          >
            Xem chi tiết
          </Link>
        </Grid>
      </Grid>
    );
  }
}

Table.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  post: getPostByCatPk(state, { categoryId: CategoryPks["gia-nuoc-sach"] }),
});

export default connect(mapStateToProps)(withStyles(styles)(Table));
