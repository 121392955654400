import Axios from "axios";
import cookies from "js-cookie";

const request = Axios.create({
  // baseURL: 'https://capnuocthotnot.vn',
  // baseURL: 'http://127.0.0.1:8000',
  baseURL: "https://admin.capnuocthotnot.vn",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// request.interceptors.request.use((config) => {
//   config.headers.Authorization = `Bearer ${cookies.get('api_token')}`;
//   return config;
// });

request.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (Axios.isCancel(error)) {
      return false;
    }
    if (error && error.response && error.response.status === 401) {
      cookies.remove("api_token");
      return false;
    }
    return Promise.reject(error);
  }
);

export default request;
