import initialState, { IS_FINISHED } from "./initialState";
import createReducer from "../../utils/createReducer";
import { ActionTypes } from "../../constants";

export const TASKS = 'tasks';

const handleRunTasks = state => state.set(IS_FINISHED, false);

const handleTasksFinished = state => state.set(IS_FINISHED, true);

export default createReducer(initialState, {
  [ActionTypes.TASKS_RUN]: handleRunTasks,
  [ActionTypes.TASKS_FINISHED]: handleTasksFinished
})