import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import { getPostByCatPk } from "../../../model/posts/selectors";
import { EMPTY, CategoryPks } from "../../../constants";

const styles = {
  root: {
    width: "100%",
    background: "#ffffff",
    padding: 15,
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
    borderRadius: 4,
    border: "1px solid transparent",
    lineHeight: 1.5,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: 10,
    lineHeight: 1.3,
  },
  info: {
    padding: "10px 0px",
    color: "#8c8b8b",
    fontWeight: "none",
  },
  icon: {
    verticalAlign: "sub",
    fontSize: 22,
    marginRight: 5,
  },
};

class Invoice extends React.Component {
  render() {
    const { classes, post } = this.props;
    return (
      <Grid container>
        {!isEmpty(post) && (
          <Typography className={classes.root} component="div">
            <Typography className={classes.title}>
              {post.title.rendered}
            </Typography>
            <Typography className={classes.info}>
              <DateRangeIcon className={classes.icon} />
              {post.createdAt}
            </Typography>
            <div
              id="mobile-post"
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: post.content.rendered || EMPTY,
              }}
            ></div>
          </Typography>
        )}
      </Grid>
    );
  }
}

Invoice.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  post: getPostByCatPk(state, {
    categoryId: CategoryPks["hoa-don-dien-tu"],
  }),
});

export default connect(mapStateToProps)(withStyles(styles)(Invoice));
