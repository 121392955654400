import { genCustomSagas } from '../core/saga';
import types from './types';
import actions from './actions';
import { getAdminUser, loginAdmin, logoutAdmin } from './services';

export default genCustomSagas([
  {
    type: types.API_ADMIN_GET,
    apiService: getAdminUser,
    successFunc: actions.storeObj,
    errorFunc: actions.fail
  },
  {
    type: types.API_ADMIN_LOGIN,
    apiService: loginAdmin,
    successFunc: actions.storeObj,
    errorFunc: actions.fail
  },
  {
    type: types.API_ADMIN_LOGOUT,
    apiService: logoutAdmin,
    successFunc: actions.removeObj,
    errorFunc: actions.fail
  }
]);
