export const fireAction = (type, data, error, ctx) => ({
  type,
  data,
  error,
  ctx
});

/**
 * Return base actions for a data model.
 * There are two types of base actions: 1st for actions related to API calls, 2nd for actions related to Redux store
 * - storeAll: (redux-related) action to store all objects.
 * - storeObj: (redux-related) action to store a newly created object.
 * - updateObj: (redux-related) action to update an existing object.
 * - removeObj: (redux-related) action to remove an existing object.
 *
 * - get: (api-related) action to get all objects from backend
 * - create: (api-related) action to create a new object.
 * - update: (api-related) action to update an existing object.
 * - delete: (api-related) action to delete an existing object.
 *
 * @param {*} T Action types for data model which generated by `./types.js`
 */
const genActions = T => ({
  storeAll: (data, ctx) => fireAction(T.STORE_ALL, data, null, ctx),
  storeObj: (data, ctx) => fireAction(T.STORE_OBJ, data, null, ctx),
  updateObj: (data, ctx) => fireAction(T.UPDATE_OBJ, data, null, ctx),
  removeObj: (data, ctx) => fireAction(T.REMOVE_OBJ, data, null, ctx),

  get: (ctx, data = null) => fireAction(T.API_GET, data, null, ctx),
  findOne: (data, ctx) => fireAction(T.API_FIND_ONE, data, null, ctx),
  create: (data, ctx) => fireAction(T.API_CREATE, data, null, ctx),
  update: (data, ctx) => fireAction(T.API_UPDATE, data, null, ctx),
  delete: (data, ctx) => fireAction(T.API_DELETE, data, null, ctx),
  fail: (data, ctx) => fireAction(T.API_FAILED, null, data, ctx),

  clearApiError: ctx => fireAction(T.CLEAR_ERROR, null, ctx)
});

export default genActions;
