import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Layout, Images } from "../../../constants";
import Typography from "@material-ui/core/Typography";
import PlaceIcon from "@material-ui/icons/Place";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import PublicIcon from "@material-ui/icons/Public";
import PrintIcon from "@material-ui/icons/Print";
import Visitor from "./Visitor";

const styles = {
  root: {
    height: 228,
    justifyContent: "center",
    background: `#2980b9 url(${Images.FOOTER}) no-repeat`,
    backgroundSize: "cover",
  },
  content: {
    maxWidth: Layout.MAX_WIDTH,
    height: "100%",
    alignItems: "center",
    color: "#ffffff",
  },
  right: {
    textAlign: "right",
  },
  text: {
    fontSize: 14,
    fontWeight: 200,
  },
  title: {
    fontWeight: "bold",
  },
  icon: {
    verticalAlign: "sub",
    fontSize: 18,
    marginRight: 5,
  },
};

class Footer extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item container className={classes.content}>
          <Grid item xs={8}>
            <Typography className={classes.title}>
              Công ty Cổ phần Cấp nước Thốt Nốt (THOTNOTWASCO)
            </Typography>
            <Typography className={classes.text}>
              <PlaceIcon className={classes.icon} />
              Địa chỉ: 392, Quốc lộ 91, Long Thạnh A, Phường Thốt Nốt, Quận Thốt
              Nốt, Thành phố Cần Thơ, Việt Nam
            </Typography>
            <Typography className={classes.text}>
              <PhoneIcon className={classes.icon} />
              Điện thoại: (0292) - 3851354
            </Typography>
            <Typography className={classes.text}>
              <PrintIcon className={classes.icon} />
              Fax: (0292) - 3855289
            </Typography>
            <Typography className={classes.text}>
              <MailIcon className={classes.icon} />
              Email: capnuocthotnot@gmail.com
            </Typography>
            <Typography className={classes.text}>
              <PublicIcon className={classes.icon} />
              Website: https://www.capnuocthotnot.vn
            </Typography>
          </Grid>
          <Visitor classes={classes} />
        </Grid>
      </Grid>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Footer);
