import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty, isEqual } from "lodash";
import RelatePosts from "./RelatePosts";
import PostDetails from "../../../components/PostDetails";
import { getPostByPath } from "../../../model/posts/selectors";
import postsActions from "../../../model/posts/actions";
import tagsActions from "../../../model/tags/actions";
import { EMPTY } from "../../../constants";
import { getCategoryIdsByPost } from "../../../model/categories/selectors";
import "./styles.scss";

const styles = {
  title: {
    fontSize: 20,
    lineHeight: 1.3,
    marginBottom: 0,
  },
  info: {
    padding: "0px 15px",
    fontSize: 14,
  },
  content: {
    fontSize: 15,
    color: "#222",
  },
  icon: {
    fontSize: 18,
  },
  print: {
    right: 0,
    top: 82,
  },
};

class Post extends React.Component {
  componentDidMount() {
    const { categories, fetchPosts, post } = this.props;
    if (categories && isEmpty(post)) {
      fetchPosts({ categories, limit: 100 });
    }
    if (!isEmpty(post)) {
      const { fetchTags } = this.props;
      fetchTags(post.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { post } = this.props;
    if (!isEmpty(post) && !isEqual(post, prevProps.post)) {
      const { fetchTags } = this.props;
      fetchTags(post.id);
    }
  }

  render() {
    const { classes, post, location } = this.props;
    return (
      <Grid container>
        <PostDetails
          post={post}
          classes={{
            title: classes.title,
            icon: classes.icon,
            info: classes.info,
            content: classes.content,
            print: classes.print,
          }}
          location={location}
        />
        <RelatePosts location={location} />
      </Grid>
    );
  }
}

Post.defaultProps = {
  location: {
    pathname: EMPTY,
  },
  categories: null,
};

Post.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
  categories: PropTypes.arrayOf(Object),
  fetchPosts: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  post: getPostByPath(state, props),
  categories: getCategoryIdsByPost(state, props),
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: categories => dispatch(postsActions.get(null, categories)),
  fetchTags: data => dispatch(tagsActions.get(null, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Post));
