import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

class Item extends PureComponent {
  handleClick = () => {
    const { item, onClick } = this.props;
    if (item && item.id) {
      onClick(item.id);
    }
  };
  render() {
    const { classes, item } = this.props;
    return (
      <Link
        to={`/${item.path}/${item.slug}`}
        className={classes.item}
        onClick={this.handleClick}
      >
        <ChevronRightIcon className={classes.icon} />
        {item.title.rendered}
        <em className={classes.time}>{` (${item.shortDate})`}</em>
      </Link>
    );
  }
}

Item.defaultProps = {
  onClick: () => {},
};

Item.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
};

export default Item;
