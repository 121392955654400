import genSaga from "../core/saga";
import types from "./types";
import actions from "./actions";
import { getPostViews, updatePostView } from "./services";

export default genSaga(
  types,
  {
    get: getPostViews,
    create: updatePostView,
  },
  actions
);
