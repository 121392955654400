import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import Fax from '@material-ui/icons/LibraryBooks';
import Link from '@material-ui/core/Link';

const info = [
  {
    id: 1,
    title: 'Bộ phận Chăm sóc khách hàng',
    phone: '0292 3851354',
    fax: '0292 3855289',
    email: 'chamsockhachhangcntn@gmail.com',
    description: 'Bộ phận tiếp nhận và giải quyết các yêu cầu, đề nghị, ý kiến liên quan đến hoạt động chính của doanh nghiệp'
  },
  // {
  //   id: 2,
  //   title: 'Phòng Kỹ thuật',
  //   phone: '0292 3851360',
  //   description: 'Bộ phận tiếp nhận và giải quyết các câu hỏi liên quan đến kỹ thuật'
  // },
  // {
  //   id: 3,
  //   title: 'Trạm cấp nước Vĩnh Thạnh',
  //   phone: '0292 3858847 - 0292 3651157'
  // },
  // {
  //   id: 4,
  //   title: 'Trạm cấp nước Thạnh An',
  //   phone: '0292 3651157'
  // }
];

class Information extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {info.map(item => (
          <Grid key={item.id} item container className={classes.panel}>
            <Typography className={classes.panelHeading}>{item.title}</Typography>
            <Grid item container className={classes.panelContent}>
              <Typography className={classes.description}>{item.description}</Typography>
              <Typography className={classes.text}>
                <Phone className={classes.icon} />
                {`Điện thoại: ${item.phone}`}
              </Typography>
              {item.fax && (
                <Typography className={classes.text}>
                  <Fax className={classes.icon} />
                  {`Fax: ${item.fax}`}
                </Typography>
              )}
              {item.email && (
                <Typography className={classes.text}>
                  <Email className={classes.icon} />
                  {`Email: ${item.email}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        ))}
        <Grid item container className={classes.panel}>
          <Link href="https://goo.gl/maps/6ueFeo5VJ3CGBde9A" color="inherit" className={classes.img} target="_blank" rel="noopener noreferrer">
            <React.Fragment />
          </Link>
        </Grid>
      </Grid>
    );
  }
}

Information.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
}

export default Information;