import React, { Component } from "react";
import { connect } from "react-redux";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import Desktop from "../pc/app";
import Mobile from "../mobile";
import Loading from "../loading";
import { runTasks } from "../../actions/tasks";
import { isFinished } from "../../reducers/tasks/selectors";
import "./app.scss";

class App extends Component {
  componentDidMount() {
    const { run } = this.props;
    run();
  }

  render() {
    const { width, finished } = this.props;
    if (!finished) {
      return <Loading />;
    }
    if (isWidthDown("sm", width)) {
      return <Mobile />;
    }
    return <Desktop />;
  }
}

App.propTypes = {
  width: PropTypes.string.isRequired,
  finished: PropTypes.bool.isRequired,
  run: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  finished: isFinished(state),
});

const mapDispatchToProps = dispatch => ({
  run: () => dispatch(runTasks()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withWidth()(App)));
