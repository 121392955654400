import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { EMPTY, RoutePaths } from "../../../constants";
import postsActions from "../../../model/posts/actions";

class TopMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: EMPTY,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(event) {
    this.setState({ searchValue: event.target.value });
  }

  handleSearch() {
    const { searchValue } = this.state;
    const { search } = this.props;
    search({ search: searchValue, limit: 100, isSearch: true });
  }
  render() {
    const { classes, onOpen } = this.props;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.search}>
            <Link
              to={RoutePaths.SEARCH}
              className={classes.searchIcon}
              onClick={this.handleSearch}
            >
              <SearchIcon />
            </Link>
            <InputBase
              placeholder="Tìm kiếm..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={this.handleChange}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

TopMenu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onOpen: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  search: data => dispatch(postsActions.get(null, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(TopMenu);
