import { finishedTasks } from "../actions/tasks";
import store from "../store";
import categoriesTask from "./categoriesTask";
import postsTask from "./postsTask";
import visitorsTask from "./visitorsTask";
import mediaTask from "./mediaTask";
import acfTask from "./acfTask";
import tagsTask from "./tagsTask";
import { selectMenuItem } from "../apps/pc/menu/actions";
import { getFirstPath } from "../utils/url";
import { CategoryPks } from "../constants";
import postViewsTask from "./postViewsTask";

const runTasks = async () => {
  try {
    await Promise.all([
      postsTask(),
      categoriesTask(),
      visitorsTask(),
      mediaTask(),
      tagsTask(),
      postViewsTask(),
      acfTask(),
    ]);
  } finally {
    store.dispatch(selectMenuItem(CategoryPks[getFirstPath(window.location)]));
    store.dispatch(finishedTasks());
  }
};

export default runTasks;
