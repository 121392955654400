import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import { CategoryPks } from "../../../constants";
import { getPostByCatPk } from "../../../model/posts/selectors";
import PostDetails from "../../../components/PostDetails";

class Invoice extends React.Component {
  render() {
    const { post } = this.props;
    if (isEmpty(post)) {
      return <React.Fragment />;
    }
    return (
      <Grid container>
        <PostDetails post={post} />
      </Grid>
    );
  }
}

Invoice.propTypes = {
  post: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  post: getPostByCatPk(state, {
    categoryId: CategoryPks["hoa-don-dien-tu"],
  }),
});

export default connect(mapStateToProps)(Invoice);
