import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import { CategoryPks } from "../../../../constants";
import { getSelectedMenuItem } from "../selectors";
import { selectMenuItem } from '../actions';
import MenuItem from "./MenuItem";
import styles from './styles'
import { getCategories } from "../../../../model/categories/selectors";

class Menu extends Component {

  handleClick = event => {
    const { id } = event.currentTarget;
    const { selectMenuItem, selectedMenuItem } = this.props;
    const parseId = parseInt(id, 10);
    if (selectedMenuItem !== parseId) {
      selectMenuItem(parseId);
    }
  }

  render() {
    const { classes, categories, selectedMenuItem } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item container className={classes.banel}>
          <div className={classes.img} />
        </Grid>
        <Grid item container className={classes.menu}>
          <ButtonGroup variant="contained" className={classes.menuGroup} classes={{ groupedContainedHorizontal: classes.divider }}>
            <Button id={CategoryPks["tin-tuc"]} className={classes.button} onClick={this.handleClick}>
              <Link to="/" className={classes.home}>
                <HomeIcon className={classes.icon} />
              </Link>
            </Button>
            {categories.map(item => (
              <MenuItem key={item.id} classes={classes} item={item} onClick={this.handleClick} selected={selectedMenuItem === item.id} />
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }
}

Menu.defaultProps = {
  selectedMenuItem: -1
};

Menu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  selectedMenuItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectMenuItem: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(Object).isRequired
}

const mapStateToProps = state => ({
  selectedMenuItem: getSelectedMenuItem(state),
  categories: getCategories(state)
})

const mapDispatchToProps = dispatch => ({
  selectMenuItem: id => dispatch(selectMenuItem(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu));