import React from "react";
import { Link } from "react-router-dom";
import { Grid, Chip, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PropTypes from "prop-types";
import { getMiddlePath } from "../../utils/url";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0px 15px 5px",
    "& > a": {
      margin: theme.spacing(0.25),
    },
  },
  sizeSmall: {
    height: 18,
  },
  icon: {
    fontSize: 20,
  },
  tag: {
    display: "flex",
    alignItems: "center",
    marginRight: 5,
  },
  text: {
    fontWeight: "bold",
  },
  chip: {
    cursor: "pointer",
  },
});

class Tags extends React.PureComponent {
  render() {
    const { classes, tags, location } = this.props;
    const path = getMiddlePath(location);
    return (
      <Grid container className={classes.root}>
        <Grid item className={classes.tag}>
          <LoyaltyIcon className={classes.icon} />
          <Typography className={classes.text}>Từ khoá:</Typography>
        </Grid>
        {tags.map(tag => (
          <Link key={tag.id} to={`/${path}?tag=${tag.slug}`}>
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label={tag.name}
              classes={{ root: classes.chip, sizeSmall: classes.sizeSmall }}
            />
          </Link>
        ))}
      </Grid>
    );
  }
}

Tags.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  tags: PropTypes.arrayOf(Object).isRequired,
};

export default withStyles(styles)(Tags);
