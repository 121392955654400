import genSaga from '../core/saga';
import types from './types';
import actions from './actions';
import { updateVisitors, getVisitors } from './services';

export default genSaga(
  types,
  {
    get: getVisitors,
    create: updateVisitors
  },
  actions
);
