import request from "../../services/request";

export const giveFeedback = async data =>
  request
    .post("https://capnuocthotnot.vn/api/v1/feedback/give_feedback", data)
    .then(res => {
      const { data } = res;
      if (!data) {
        return {};
      }
      return { ...data, pk: "contact" };
    });

export default {};
