import {
  take, call, all
} from 'redux-saga/effects';
import runTasks from '../tasks';
import { ActionTypes } from '../constants';


function* runTasksSaga() {
  while (true) {
    try{
      yield take(ActionTypes.TASKS_RUN);
      yield call(runTasks);
    }
    catch(err) {
      console.error('runTasksSaga', err);
    }
  }
}

function* taskSaga() {
  yield all([runTasksSaga()]);
}

export default taskSaga;
