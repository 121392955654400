import genSaga from '../core/saga';
import types from './types';
import actions from './actions';
import { giveFeedback } from './services';

export default genSaga(
  types,
  {
    create: giveFeedback
  },
  actions
);
