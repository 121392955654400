import genActions, { fireAction } from "../core/actions";
import types from "./types";

const actions = genActions(types);

actions.adminGet = ctx => fireAction(types.API_ADMIN_GET, null, null, ctx);

actions.adminLogin = (data, ctx) =>
  fireAction(types.API_ADMIN_LOGIN, data, null, ctx);

actions.adminLogout = (data, ctx) =>
  fireAction(types.API_ADMIN_LOGOUT, data, null, ctx);

export default actions;
