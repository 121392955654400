import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PostSlider from "./PostSlider";
import Popular from "./Popular";
import { getPostNews } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/post_views/actions";

const styles = {
  root: {
    background: "#ffffff",
  },
  content: {
    padding: "0px 5px",
  },
  divider: {
    width: "100%",
  },
  img: {
    border: "1px solid #dfdfdf",
    width: 110,
    height: 70,
  },
  popular: {
    color: "#337ab7",
    height: 70,
    "&:hover": {
      color: "#23527c",
    },
  },
  title: {
    lineHeight: 1.4,
    fontSize: 15,
    fontWeight: "normal",
    color: "#000000",
  },
};

class News extends React.Component {
  render() {
    const { classes, posts, updatePost } = this.props;
    return (
      <Grid item container className={classes.root}>
        <Grid item xs={7}>
          <PostSlider posts={posts} updatePost={updatePost} />
        </Grid>
        <Grid item xs={5} className={classes.content}>
          {posts.map(post => {
            return (
              <Popular
                key={post.id}
                classes={classes}
                post={post}
                updatePost={updatePost}
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

News.defaultProps = {
  posts: [],
};

News.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  posts: PropTypes.arrayOf(Object),
  updatePost: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  posts: getPostNews(state),
});

const mapDispatchToProps = dispatch => ({
  updatePost: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(News));
