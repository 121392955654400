import { connect } from "react-redux";
import PostNews from "../../../components/PostNews";
import { makeGetPostsByCatPk } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/post_views/actions";

const makeMapStateToProps = () => {
  const getPosts = makeGetPostsByCatPk();
  return (state, props) => ({
    posts: getPosts(state, props),
  });
};

const mapDisaptchToProps = dispatch => ({
  onClick: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  makeMapStateToProps,
  mapDisaptchToProps
)(PostNews);
