import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import store from "../store";
import postsAction from "../model/posts/actions";
import { getPosts } from "../model/posts/services";

const postsByCategoriesTask = async () => {
  let posts = [];
  let page = 1;
  let isFetch = true;
  while (isFetch) {
    try {
      const res = await getPosts({ limit: 100, page });
      if (isEmpty(res) || res.length < 100) {
        isFetch = false;
      }
      posts = posts.concat(res);
      page += 1;
    } catch (error) {
      isFetch = false;
      console.error(get(error, "response.data.message"));
    }
  }
  store.dispatch(postsAction.storeAll(posts));
  return posts;
};

export default postsByCategoriesTask;
