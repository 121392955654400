import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { Map } from 'immutable';
import isProduction from './utils/isProduction';
import reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

let localStore;

if (isProduction()) {
  localStore = createStore(reducers, Map(), applyMiddleware(sagaMiddleware));
} else {
  //  eslint-disable-next-line no-underscore-dangle
  const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  localStore = createStore(
    reducers,
    Map(),
    enhancer(applyMiddleware(sagaMiddleware, createLogger()))
  );
}
// Run saga middleware
sagaMiddleware.run(rootSaga);
const store = localStore;

export default store;
