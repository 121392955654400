import genSelectors from "../core/selectors";
import { MODEL } from "../core/reducer";
import { TAGS_MODEL } from "./reducer";
import { createSelector } from "reselect";
import { isMapEmpty } from "../../utils/empty";

const selectors = genSelectors([MODEL, TAGS_MODEL]);

export const getTags = createSelector(
  selectors.getObjs,
  tags => (isMapEmpty(tags) ? {} : tags.toJS())
);

export const getTag = createSelector(
  [selectors.getObjs, (_, props) => props.slug],
  (tags, slug) => {
    if (!slug || isMapEmpty(tags)) {
      return null;
    }
    const tag = tags.find(tag => tag.get("slug") === slug);
    return tag ? tag.get("id") : null;
  }
);

export default selectors;
