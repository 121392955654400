import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import PopularPost from "../../../components/PopularPost";

class Popular extends React.PureComponent {
  handleCick = id => {
    const { updatePost } = this.props;
    updatePost(id);
  };
  render() {
    const { classes, post, divider } = this.props;
    return (
      <Grid item container>
        <PopularPost
          post={post}
          classes={{
            root: classes.popular,
            img: classes.img,
            title: classes.title,
          }}
          onClick={this.handleCick}
        />
        {divider && <Divider className={classes.divider} />}
      </Grid>
    );
  }
}

Popular.defaultProps = {
  divider: false,
};

Popular.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
  divider: PropTypes.bool,
  updatePost: PropTypes.func.isRequired,
};

export default Popular;
