import initialState, { LOADING, DATA, ERROR } from "./initialState";
import { fromJS, List } from "immutable";
import createReducer from "../../utils/createReducer";
import Types from "./types";

export const POSTS = 'posts';

export const handleRequest = (state, action) => state
  .setIn([LOADING, action.id], true)
  .setIn([ERROR, action.id], null);

export const handleSuccess = (state, action) => state
  .setIn([LOADING, action.id], false)
  .setIn([DATA, action.id], List(action.data))
  .setIn([ERROR, action.id], null);

export const handleFailed = (state, action) => state
  .setIn([LOADING, action.id], false)
  .setIn([ERROR, action.id], fromJS(action.error));

export default createReducer(initialState, {
  [Types.GET_HOME_POSTS_REQUEST]: handleRequest,
  [Types.GET_HOME_POSTS_SUCCESS]: handleSuccess,
  [Types.GET_HOME_POSTS_FAILED]: handleFailed,
  [Types.SEARCH_POSTS_REQUEST]: handleRequest,
  [Types.SEARCH_POSTS_SUCCESS]: handleSuccess,
  [Types.SEARCH_POSTS_FAILED]: handleFailed
})