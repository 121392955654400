import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Images } from "../../../constants";
import { getMediaByKey } from "../../../utils/media";

class PostItem extends React.Component {
  render() {
    const { classes, post, activeStep, index } = this.props;
    const image = getMediaByKey(post, "full");
    return (
      <Link to={`${post.path}/${post.slug}`} className={classes.link}>
        {Math.abs(activeStep - index) <= 2 ? (
          <img
            className={classes.img}
            src={image ? image.source_url : Images.AVARTA_POST}
            alt={post.title}
          />
        ) : null}
      </Link>
    );
  }
}

PostItem.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  activeStep: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
};

export default PostItem;
