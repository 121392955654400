import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import Posts from "../posts";
import PostDetail from "../post";
import { isCategory } from "../../../model/categories/selectors";

let cache = null;

class Post extends React.Component {
  render() {
    const { location, match, isShowPosts } = this.props;
    if (isEqual(cache, location)) {
      return <React.Fragment />;
    }
    if (isShowPosts) {
      cache = location;
      return <Posts location={location} match={match} />;
    }
    cache = null;
    return <PostDetail location={location} match={match} />;
  }
}

Post.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  isShowPosts: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  isShowPosts: isCategory(state, props),
});

export default connect(mapStateToProps)(Post);
