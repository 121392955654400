import {
  customizeReducer,
  createModelReducer,
  stopLoading,
} from "../core/reducer";
import types from "./types";
import initialState from "./initialState";
import { MODEL_OBJS } from "../core/initState";
import { List, fromJS } from "immutable";
import { isListEmpty } from "../../utils/empty";

export const POSTS_MODEL = "posts";

export const storeObjs = (state, { data: objs }) => {
  const updatedState = stopLoading(state);

  if (!objs) return updatedState;

  const imObjs = fromJS(objs);
  if (List.isList(imObjs)) {
    let data = updatedState.get(MODEL_OBJS) || List();
    if (isListEmpty(data)) {
      return updatedState.set(MODEL_OBJS, imObjs);
    }
    for (let i = 0; i < imObjs.size; i += 1) {
      const id = imObjs.getIn([i, "id"]);
      const index = data.findIndex(el => el.get("id") === id);
      data =
        index < 0 ? data.push(imObjs.get(i)) : data.set(index, imObjs.get(i));
    }
    return updatedState.set(MODEL_OBJS, data);
  }
  const data = updatedState.get(MODEL_OBJS) || Map();
  return updatedState.set(MODEL_OBJS, data.merge(imObjs));
};

const customReducers = customizeReducer(types);

customReducers[types.STORE_ALL] = storeObjs;

export default createModelReducer(customReducers, initialState);
