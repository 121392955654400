import { combineReducers } from "redux-immutable";
import postReducer, { POSTS_MODEL } from "./posts/reducer";
import userReducer, { USER_MODEL } from "./user/reducer";
import categoryReducer, { CATEGORIES_MODEL } from "./categories/reducer";
import visitorsReducer, { VISITORS_MODEL } from "./visitors/reducer";
import mediaReducer, { MEDIA_MODEL } from "./media/reducer";
import acfReducer, { ACF_MODEL } from "./acf/reducer";
import tagsReducer, { TAGS_MODEL } from "./tags/reducer";
import contactReducer, { CONTACT_MODEL } from "./contact/reducer";
import postViewsReducer, { POST_VIEWS_MODEL } from "./post_views/reducer";

const reducers = combineReducers({
  [POSTS_MODEL]: postReducer,
  [USER_MODEL]: userReducer,
  [CATEGORIES_MODEL]: categoryReducer,
  [VISITORS_MODEL]: visitorsReducer,
  [MEDIA_MODEL]: mediaReducer,
  [CONTACT_MODEL]: contactReducer,
  [POST_VIEWS_MODEL]: postViewsReducer,
  [TAGS_MODEL]: tagsReducer,
  [ACF_MODEL]: acfReducer,
});

export default reducers;
