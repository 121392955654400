import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { getSearchPosts } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/post_views/actions";
import PostItem from "../../../components/PostItem";

class Search extends React.Component {
  handleClick = id => {
    const { updatePost } = this.props;
    updatePost(id);
  };

  render() {
    const { posts } = this.props;
    return (
      <Grid container>
        {posts.map(post => {
          return (
            <PostItem key={post.id} post={post} onClick={this.handleClick} />
          );
        })}
      </Grid>
    );
  }
}

Search.propTypes = {
  posts: PropTypes.arrayOf(Object).isRequired,
  updatePost: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  posts: getSearchPosts(state),
});

const mapDispatchToProps = dispatch => ({
  updatePost: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
