import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Activity from "../activity";
import Modules from "../../../components/Modules";
import Posts from "./Posts";
import Table from "../../table";
import Service from "../service";

const styles = {
  modules: {
    marginTop: 10,
    borderRadius: 0,
  },
  table: {
    marginTop: 10,
  },
};

class Container extends React.PureComponent {
  render() {
    const { classes, location } = this.props;
    return (
      <Grid container>
        <Posts location={location} />
        <Activity />
        <Modules classes={{ root: classes.modules }} />
        <Table classes={{ root: classes.table }} />
        <Service />
      </Grid>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Container);
