import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { Link } from "react-router-dom";
import { Color, Images } from "../../../constants";
import { getMediaByKey } from "../../../utils/media";

const styles = {
  root: {
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
    borderRadius: 4,
    border: "1px solid #dddddd",
    padding: "15px 15px 30px",
    backgroundColor: "#ffffff",
    minHeight: 150,
    marginBottom: 10,
    "&:hover > $img:hover": {
      transform: "scale(1.02)",
      transition: "all 250ms",
    },
  },
  img: {
    height: 230,
    overflow: "hidden",
    backgroundSize: "100% 100% !important",
    border: "1px solid #dfdfdf",
    marginBottom: 5,
  },
  title: {
    fontSize: 18,
    lineHeight: 1.3,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#171717",
    width: "100%",
    "&:hover": {
      color: Color.PRIMARY,
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  icon: {
    color: "#8c8b8b",
    fontSize: 18,
  },
  text: {
    color: "#8c8b8b",
    fontSize: 14,
  },
  description: {
    fontSize: 15,
    color: "#000000",
  },
  link: {
    width: "100%",
  },
};

class PostItem extends React.PureComponent {
  handleClick = () => {
    const { onClick, post } = this.props;
    if (post && post.id) {
      onClick(post.id);
    }
  };
  render() {
    const { classes, post } = this.props;
    const image = getMediaByKey(post, "full");
    let path = post.path;
    if(path && path !== '/') {
      path = `/${post.path}/${post.slug}`;
    } else {
      path = `/${post.slug}`;
    }
    return (
      <Link
        to={path}
        className={classes.link}
        onClick={this.handleClick}
      >
        <Grid container className={classes.root}>
          <Grid
            item
            container
            className={classes.img}
            style={{
              background: `url(${
                image ? image.source_url : Images.AVARTA_POST
              }) no-repeat`,
            }}
          />
          <Grid item container>
            <Typography className={classes.title}>
              {post.title.rendered}
            </Typography>
            <Typography component="div" className={classes.iconWrapper}>
              <DateRangeIcon className={classes.icon} />
              <Typography component="div" className={classes.text}>
                &nbsp;&nbsp;{`${post.createdAt}`}&nbsp;&nbsp;
              </Typography>
              <RemoveRedEyeOutlinedIcon className={classes.icon} />
              <Typography component="div" className={classes.text}>
                &nbsp;&nbsp;{`${post.view || 0}`}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Link>
    );
  }
}

PostItem.defaultProps = {
  post: {},
  onClick: () => {},
};

PostItem.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};

export default withStyles(styles)(PostItem);
