import genSelectors from "../core/selectors";
import { createSelector } from "reselect";
import { MODEL } from "../core/reducer";
import { POSTS_MODEL } from "./reducer";
import { isListEmpty, isMapEmpty } from "../../utils/empty";
import { EMPTY, SLASH, CategoryPks } from "../../constants";
import { getCatPk } from "../../utils/category";
import { getLastPath } from "../../utils/url";
import {
  groupCategoriesById,
  groupCategoriesBySlug,
} from "../categories/selectors";
import { getMedia } from "../media/selectors";
import { getTags } from "../tags/selectors";
import postViewsSelectors from "../post_views/selectors";
import { getAcfs } from "../acf/selectors";
import { get, isEmpty, sortBy, orderBy } from "lodash";
import { formatDate } from "../../utils/date";

const LIMIT_POST = 4;

const selectors = genSelectors([MODEL, POSTS_MODEL]);

const getPath = (_, props) => props.location || EMPTY;

const getTagId = (_, props) => props.tagId;

const getParmeterUrl = (_, props) => get(props, "match.params.slug");

const getCategoryId = (_, props) => props.id;

export const getPosts = createSelector(
  [
    selectors.getObjs,
    groupCategoriesById,
    getMedia,
    postViewsSelectors.getObjs,
    getAcfs,
  ],
  (imPosts, categories, mediaObjs, postViews, acfs) => {
    if (isListEmpty(imPosts) || isEmpty(categories)) {
      return [];
    }
    const posts = imPosts.toJS();
    const data = [];
    posts.forEach(post => {
      if (mediaObjs[post.featured_media]) {
        post.media = mediaObjs[post.featured_media].media_details;
      }
      post.shortDate = formatDate(post.date, false, "dd/MM/yyyy");
      post.createdAt = formatDate(post.date, true);
      if(post.categories.includes(34)) {
          post.path = 'ket-qua-mau-nuoc'
      } else {
          post.path = sortBy(post.categories)
              .map(catId => catId && categories[catId] && categories[catId].slug)
              .join("/");
      }
      post.view = postViews.getIn([`${post.id}`, "view"]) || 0;
      post.description = get(acfs, [post.id, "acf", "description"]);
      data.push(post);
    });
    return orderBy(data, ["date"], ["desc"]);
  }
);

const filterPosts = (posts, catPk) =>
  posts.filter(post => post && post.categories.includes(catPk));

export const getPostsByCatPk = createSelector(
  [getPosts, getPath],
  (posts, location) => {
    if (!location || isMapEmpty(posts)) {
      return [];
    }
    return filterPosts(posts, getCatPk(location));
  }
);

export const getRelatePosts = createSelector(
  [getPosts, getPath],
  (posts, location) => {
    if (!location || isEmpty(posts)) {
      return [];
    }
    const paths = location.pathname.split(SLASH);
    const { length } = paths;
    const slug = paths[length - 1];
    const catPk = CategoryPks[paths[length - 2]];

    return posts.filter(
      post => post && post.slug !== slug && post.categories.includes(catPk)
    );
  }
);

export const getPostNews = createSelector(
  getPosts,
  posts => {
    if (isEmpty(posts)) {
      return [];
    }
    const news = [];
    for (let index = 0; index < posts.length; index++) {
      if (index >= LIMIT_POST) {
        break;
      }
      news.push(posts[index]);
    }
    return news;
  }
);

export const makeGetPostsByCatPk = () =>
  createSelector(
    [getPosts, getCategoryId],
    (posts, catPk) => {
      if (!catPk || isEmpty(posts)) {
        return [];
      }
      return posts.filter(
        post => post && post.categories && post.categories.includes(catPk)
      );
    }
  );

export const getPostsByPath = createSelector(
  [getPosts, groupCategoriesBySlug, getPath, getTagId],
  (posts, slugCategories, path, tagId) => {
    if (isEmpty(posts) || isEmpty(slugCategories)) {
      return [];
    }
    const slug = getLastPath(path);
    const category = slugCategories[slug];
    if (!category) {
      return [];
    }
    if (!tagId) {
      return posts.filter(
        post => post && post.categories && post.categories.includes(category.id)
      );
    }
    return posts.filter(
      post =>
        post &&
        post.categories &&
        post.categories.includes(category.id) &&
        post.tags.includes(tagId)
    );
  }
);

export const getPostByPath = createSelector(
  [getPosts, getParmeterUrl, getTags],
  (posts, slug, tags) => {
    if (!slug || isEmpty(posts)) {
      return { title: { rendered: "" }, content: { rendered: "" } };
    }
    const post = posts.find(post => post && post.slug === slug);
    if (!post) {
      return { title: { rendered: "" }, content: { rendered: "" } };
    }
    const postTags =
      isEmpty(post.tags) || isEmpty(tags)
        ? []
        : post.tags.map(tagId => tags[tagId]);
    return { ...post, tags: postTags };
  }
);

export const getPostByCatPk = createSelector(
  [getPosts, (_, props) => props.categoryId],
  (posts, categoryId) => {
    if (!categoryId || isEmpty(posts)) {
      return { title: { rendered: "" }, content: { rendered: "" } };
    }
    const post = posts.find(
      post => post && post.categories && post.categories.includes(+categoryId)
    );
    return post || {};
  }
);

export const getSearchPosts = createSelector(
  getPosts,
  posts => {
    if (isEmpty(posts)) {
      return [];
    }
    return posts.filter(post => post && post.isSearch);
  }
);

export default selectors;
