import { combineReducers } from 'redux-immutable';
import model from './model/reducers';
import menuReducer, { MENU } from './apps/pc/menu/reducer';
import taskRedcuer, { TASKS } from './reducers/tasks';
import postsReducer, { POSTS } from './reducers/posts';
import notifyReducer, { NOTIFY } from './apps/notify/reducer';

const reducers = combineReducers({
  model,
  [MENU]: menuReducer,
  [TASKS]: taskRedcuer,
  [POSTS]: postsReducer,
  [NOTIFY]: notifyReducer
});

export default reducers;
