import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LeftMenu from "./left_menu";
import styles from "./styles";
import TopMenu from "./top_menu";
import Home from "./home";
import Footer from "./footer";
import { RoutePaths } from "../../constants";
import Contact from "../contact";
import Invoice from "./invoice";
import Search from "./search";
import Post from "./Post";

class Mobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }
  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <Router>
          <CssBaseline />
          <TopMenu classes={classes} onOpen={this.handleDrawerOpen} />
          <LeftMenu
            classes={classes}
            onClose={this.handleDrawerClose}
            open={open}
          />
          <main className={classes.container}>
            <div className={classes.drawerHeader} />
            <div className={classes.content}>
              <Switch>
                <Route exact path="/" component={Home} />

                <Route exact path={RoutePaths.GIOI_THIEU} component={Post} />
                <Route exact path={RoutePaths.TO_CHUC} component={Post} />
                <Route exact path={RoutePaths.SO_LUOC} component={Post} />
                <Route
                  exact
                  path={`${RoutePaths.GIOI_THIEU}${RoutePaths.TO_CHUC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.GIOI_THIEU}${RoutePaths.SO_LUOC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.GIOI_THIEU}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TO_CHUC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.SO_LUOC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.GIOI_THIEU}${RoutePaths.TO_CHUC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.GIOI_THIEU}${RoutePaths.SO_LUOC}/:slug`}
                  component={Post}
                />

                <Route exact path={RoutePaths.TIN_TUC} component={Home} />
                <Route exact path={RoutePaths.HOAT_DONG_CTY} component={Post} />
                <Route
                  exact
                  path={RoutePaths.KHOA_HOC_CONG_NGHE}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.TIN_DIA_PHUONG}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.DIEM_TIN_NGANH_NUOC}
                  component={Post}
                />
                <Route exact path={RoutePaths.GIAO_LUU} component={Post} />
                <Route
                  exact
                  path={RoutePaths.HOI_THAO_HOI_THAO}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.HOAT_DONG_CTY}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.KHOA_HOC_CONG_NGHE}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.TIN_DIA_PHUONG}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.DIEM_TIN_NGANH_NUOC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.GIAO_LUU}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.HOI_THAO_HOI_THAO}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG_CTY}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.KHOA_HOC_CONG_NGHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.KHOA_HOC_CONG_NGHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_DIA_PHUONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.GIAO_LUU}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOI_THAO_HOI_THAO}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.HOAT_DONG_CTY}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.KHOA_HOC_CONG_NGHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.TIN_DIA_PHUONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.DIEM_TIN_NGANH_NUOC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.GIAO_LUU}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.TIN_TUC}${RoutePaths.HOI_THAO_HOI_THAO}/:slug`}
                  component={Post}
                />

                <Route
                  exact
                  path={RoutePaths.QUAN_HE_CO_DONG}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.DIEU_LE_QUY_CHE}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.BAO_CAO_DINH_KY}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.THONG_BAO_CO_DONG}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.DAI_HOI_DONG_CO_DONG}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.BAO_CAO_TAI_CHINH}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.DIEU_LE_QUY_CHE}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.BAO_CAO_DINH_KY}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.THONG_BAO_CO_DONG}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.DAI_HOI_DONG_CO_DONG}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.BAO_CAO_TAI_CHINH}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.DIEU_LE_QUY_CHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.BAO_CAO_DINH_KY}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.THONG_BAO_CO_DONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.DAI_HOI_DONG_CO_DONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.BAO_CAO_TAI_CHINH}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.DIEU_LE_QUY_CHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.BAO_CAO_DINH_KY}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.THONG_BAO_CO_DONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.DAI_HOI_DONG_CO_DONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.QUAN_HE_CO_DONG}${RoutePaths.BAO_CAO_TAI_CHINH}/:slug`}
                  component={Post}
                />

                <Route
                  exact
                  path={RoutePaths.SAN_PHAN_VA_DICH_VU}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.DICH_VU_CAP_NUOC}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.SAN_PHAN_VA_DICH_VU}${RoutePaths.DICH_VU_CAP_NUOC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.SAN_PHAN_VA_DICH_VU}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.DICH_VU_CAP_NUOC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.SAN_PHAN_VA_DICH_VU}${RoutePaths.DICH_VU_CAP_NUOC}/:slug`}
                  component={Post}
                />

                <Route
                  exact
                  path={RoutePaths.HO_TRO_KHACH_HANG}
                  component={Post}
                />
                <Route exact path={RoutePaths.THONG_BAO} component={Post} />
                <Route
                  exact
                  path={RoutePaths.CAC_DICH_VU_CAP_NUOC}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.KIEN_THUC_NGANH_NUOC}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}${RoutePaths.THONG_BAO}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}${RoutePaths.CAC_DICH_VU_CAP_NUOC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}${RoutePaths.KIEN_THUC_NGANH_NUOC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.THONG_BAO}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.CAC_DICH_VU_CAP_NUOC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.KIEN_THUC_NGANH_NUOC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}${RoutePaths.THONG_BAO}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}${RoutePaths.CAC_DICH_VU_CAP_NUOC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HO_TRO_KHACH_HANG}${RoutePaths.KIEN_THUC_NGANH_NUOC}/:slug`}
                  component={Post}
                />

                <Route exact path={RoutePaths.HOAT_DONG} component={Post} />
                <Route
                  exact
                  path={RoutePaths.THUC_HIEN_QUY_CHE_DC}
                  component={Post}
                />
                <Route
                  exact
                  path={RoutePaths.CONG_TAC_PHONG_CHONG_THAT_THOAT}
                  component={Post}
                />
                <Route exact path={RoutePaths.VAN_NGHE} component={Post} />
                <Route
                  exact
                  path={RoutePaths.PHONG_CHAY_CHUA_CHAY}
                  component={Post}
                />
                <Route exact path={RoutePaths.CONG_DOAN} component={Post} />
                <Route
                  exact
                  path={RoutePaths.VAN_BAN_PHAP_LUAT}
                  component={Post}
                />

                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.THUC_HIEN_QUY_CHE_DC}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.CONG_TAC_PHONG_CHONG_THAT_THOAT}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.VAN_NGHE}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.PHONG_CHAY_CHUA_CHAY}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.CONG_DOAN}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.VAN_BAN_PHAP_LUAT}`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.THUC_HIEN_QUY_CHE_DC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.CONG_TAC_PHONG_CHONG_THAT_THOAT}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.VAN_NGHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.PHONG_CHAY_CHUA_CHAY}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.CONG_DOAN}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.VAN_BAN_PHAP_LUAT}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.THUC_HIEN_QUY_CHE_DC}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.CONG_TAC_PHONG_CHONG_THAT_THOAT}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.VAN_NGHE}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.PHONG_CHAY_CHUA_CHAY}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.CONG_DOAN}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.HOAT_DONG}${RoutePaths.VAN_BAN_PHAP_LUAT}/:slug`}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.GIA_NUOC}/:slug`}
                  component={Post}
                />
                <Route path={RoutePaths.SEARCH} component={Search} />

                <Route
                  exact
                  path={RoutePaths.CHAT_LUONG_NUOC}
                  component={Post}
                />
                <Route
                  exact
                  path={`${RoutePaths.CHAT_LUONG_NUOC}:/slug`}
                  component={Post}
                />

                <Route path={RoutePaths.HOA_DON_DIEN_TU} component={Invoice} />
                <Route exact path={RoutePaths.LIEN_HE} component={Contact} />
              </Switch>
            </div>
            <Footer />
          </main>
        </Router>
      </div>
    );
  }
}

Mobile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Mobile);
