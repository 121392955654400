import { Types } from "./Types";

export const resetNotify = () => ({
  type: Types.NOTIFY_RESET
});

export const success = (data) => ({
  type: Types.NOTIFY_SUCCESS,
  data
});

export const error = (error) => ({
  type: Types.NOTIFY_ERROR,
  error
});