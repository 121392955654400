import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import postsActions from "../../../model/posts/actions";
import tagsActions from "../../../model/tags/actions";
import PostDetails from "../../../components/PostDetails";
import RelatePosts from "./RelatePosts";
import { EMPTY } from "../../../constants";
import { getPostByPath } from "../../../model/posts/selectors";
import { getCategoryIdsByPost } from "../../../model/categories/selectors";
import { isEmpty, isEqual } from "lodash";
import "./styles.scss";

class Post extends React.Component {
  componentDidMount() {
    const { post, categories, fetchPosts } = this.props;
    if (categories && isEmpty(post)) {
      fetchPosts({ categories, limit: 100 });
    }
    if (!isEmpty(post)) {
      const { fetchTags } = this.props;
      fetchTags(post.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { post } = this.props;
    if (!isEmpty(post) && !isEqual(post, prevProps.post)) {
      const { fetchTags } = this.props;
      fetchTags(post.id);
    }
  }

  render() {
    const { post, location } = this.props;
    return (
      <Grid container>
        <PostDetails id="post-pc" post={post} location={location} />
        <RelatePosts location={location} />
      </Grid>
    );
  }
}

Post.defaultProps = {
  location: {
    pathname: EMPTY,
  },
  categories: null,
};

Post.propTypes = {
  post: PropTypes.instanceOf(Object).isRequired,
  categories: PropTypes.arrayOf(Object),
  location: PropTypes.instanceOf(Object),
  fetchPosts: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  post: getPostByPath(state, props),
  categories: getCategoryIdsByPost(state, props),
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: data => dispatch(postsActions.get(null, data)),
  fetchTags: data => dispatch(tagsActions.get(null, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Post);
