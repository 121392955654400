import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import queryString from "query-string";
import PostItem from "./PostItem";
import postActions from "../../../model/posts/actions";
import postViewsActions from "../../../model/post_views/actions";
import { EMPTY } from "../../../constants";
import postsSelectors, { getPostsByPath } from "../../../model/posts/selectors";
import { getCategoryIdsByPath } from "../../../model/categories/selectors";
import { getTag } from "../../../model/\btags/selectors";
import { Typography } from "@material-ui/core";

const styles = {
  root: {
    padding: 5,
  },
  content: {
    paddingTop: 10,
  },
  img: {
    border: "1px solid #dfdfdf",
    width: 100,
    height: 60,
  },
  popular: {
    color: "#337ab7",
    height: 70,
    "&:hover": {
      color: "#23527c",
    },
  },
  title: {
    fontWeight: 500,
    lineHeight: 1.4,
    fontSize: 15,
  },
};

class PostList extends React.Component {
  componentDidMount() {
    const { fetchPosts, categories, tagId } = this.props;
    if (categories) {
      fetchPosts({ categories, tagId, limit: 100 });
    }
  }

  componentDidUpdate(prevProps) {
    const { categories, fetchPosts, tagId } = this.props;
    if (!isEqual(categories, prevProps.categories)) {
      fetchPosts({ categories, tagId, limit: 100 });
    }
  }

  render() {
    const { classes, posts, location, updatePostView, isLoading } = this.props;
    const isEmpty = !posts || posts.length < 1;
    return (
      <Grid container className={classes.root}>
        {!isEmpty &&
          posts.map(post => {
            return (
              <Grid key={post.id} item container>
                <PostItem
                  post={post}
                  path={location.pathname}
                  onClick={updatePostView}
                />
              </Grid>
            );
          })}
        {isEmpty && !isLoading && (
          <Typography
            style={{
              textAlign: "center",
              fontSize: 15,
              width: "100%",
            }}
          >
            Không tìm thấy bài viết nào
          </Typography>
        )}
      </Grid>
    );
  }
}

PostList.defaultProps = {
  isLoading: false,
  posts: [],
  tagId: null,
  location: {
    pathname: EMPTY,
  },
};

PostList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
  posts: PropTypes.arrayOf(Object),
  tagId: PropTypes.number,
  isLoading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.number),
  fetchPosts: PropTypes.func.isRequired,
  updatePostView: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const search = queryString.parse(props.location.search);
  const tagId = getTag(state, { slug: search.tag });
  return {
    isLoading: postsSelectors.isLoading(state),
    posts: getPostsByPath(state, { ...props, tagId }),
    categories: getCategoryIdsByPath(state, props),
    tagId,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchPosts: data => dispatch(postActions.get(null, data)),
  updatePostView: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PostList));
