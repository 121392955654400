import React, { Component } from "react";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import visitorsAction from '../../../model/visitors/actions'
import { getVisitors } from "../../../model/visitors/selectors";

class Visitor extends Component {
  componentDidMount() {
    const { fetchVisitors } = this.props;
    fetchVisitors();
  }
  render() {
    const { classes, visitors } = this.props;
    return (
      <Grid item xs={4} className={classes.right}>
        <Typography className={classes.text}>
          <SearchIcon className={classes.icon} />
          Máy chủ tìm kiếm: 1
            </Typography>
        <Typography className={classes.text}>
          <FlashOnIcon className={classes.icon} />
          Đang truy cập: {visitors.current || 0}
        </Typography>
        <Typography className={classes.text}>
          <FilterListIcon className={classes.icon} />
          Hôm nay: {visitors.today || 0}
        </Typography>
        <Typography className={classes.text}>Tổng lượt truy cập: {visitors.total || 0}</Typography>
      </Grid>
    );
  }
}

Visitor.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  visitors: PropTypes.instanceOf(Object).isRequired,
  fetchVisitors: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  visitors: getVisitors(state)
})

const mapDispatchToProps = dispatch => ({
  fetchVisitors: () => dispatch(visitorsAction.get('pc/app/Footer'))
})

export default connect(mapStateToProps, mapDispatchToProps)(Visitor);