import store from "../store";
import acfActions from "../model/acf/actions";
import { getAcfs } from "../model/acf/services";

const acfTask = () =>
  getAcfs().then(acfs => {
    store.dispatch(acfActions.storeAll(acfs));
    return acfs;
  });

export default acfTask;
