import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import PostNews from "./PostNews";
import News from "./News";
import { CategoryPks, EMPTY } from "../../../constants";
import { getCatPk } from "../../../utils/category";
import Posts from "../posts";
import Support from "../../support";

const postNews = [
  {
    id: CategoryPks["tin-hoat-dong-cong-ty"],
    title: "TIN HOẠT ĐỘNG CÔNG TY",
    path: "/tin-tuc/tin-hoat-dong-cong-ty",
  },
  {
    id: CategoryPks["tin-khoa-hoc-cong-nghe"],
    title: "TIN KHOA HỌC - CÔNG NGHỆ",
    path: "/tin-tuc/tin-khoa-hoc-cong-nghe",
  },
  {
    id: CategoryPks["tin-dia-phuong"],
    title: "TIN ĐỊA PHƯƠNG",
    path: "/tin-tuc/tin-dia-phuong",
  },
  {
    id: CategoryPks["diem-tin-nganh-nuoc"],
    title: "ĐIỂM TIN NGÀNH NƯỚC",
    path: "/tin-tuc/diem-tin-nganh-nuoc",
  },
  {
    id: CategoryPks["giao-luu-voi-tinh-ban"],
    title: "GIAO LƯU VỚI TỈNH BẠN",
    path: "/tin-tuc/giao-luu-voi-tinh-ban",
  },
  {
    id: CategoryPks["quan-he-co-dong"],
    title: "QUAN HỆ CỔ ĐÔNG",
    path: "/quan-he-co-dong",
  },
];

class Home extends React.PureComponent {
  render() {
    const { location } = this.props;
    const catPk = getCatPk(location);
    if (catPk === CategoryPks["tin-tuc"]) {
      return (
        <Grid container>
          <News id="home" />
          <Support />
          <Grid item container spacing={2} style={{ marginTop: 10 }}>
            {postNews.map(item => (
              <Grid key={item.id} item xs={6}>
                <PostNews id={item.id} title={item.title} path={item.path} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    }
    return <Posts location={location} />;
  }
}

Home.defaultProps = {
  location: {
    pathname: EMPTY,
  },
};

Home.propTypes = {
  location: PropTypes.instanceOf(Object),
};

export default Home;
