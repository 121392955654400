import genSelectors from '../core/selectors';
import { MODEL } from '../core/reducer';
import { VISITORS_MODEL } from './reducer';
import { createSelector } from 'reselect';
import { isMapEmpty } from '../../utils/empty';

const selectors = genSelectors([MODEL, VISITORS_MODEL]);

export const getVisitors = createSelector(selectors.getObjs, visitors => (isMapEmpty(visitors) ? {} : visitors.toJS()))

export default selectors;
