import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty, isEqual } from "lodash";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { Grid } from "@material-ui/core";
import Post from "./Post";

const styles = {
  root: {
    position: "relative",
  },
  container: {
    width: "100%",
    background: "#ffffff",
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
    borderRadius: 4,
    border: "1px solid transparent",
    lineHeight: 1.5,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    lineHeight: 1.3,
    padding: 15,
  },
  info: {
    padding: "0px 15px",
    color: "#8c8b8b",
    fontWeight: 500,
    fontSize: 16,
  },
  icon: {
    verticalAlign: "sub",
    fontSize: 22,
    marginRight: 5,
  },
  content: {
    fontSize: 15,
    padding: "10px 15px",
  },
  print: {
    cursor: "pointer",
    position: "absolute",
    color: "#8c8b8b",
    fontSize: 24,
    marginRight: 25,
    right: 10,
    top: 60,
  },
};

class PostDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { post } = this.props;
    if (!isEqual(post, prevProps.post)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { classes, post, location } = this.props;
    const data = isEmpty(post)
      ? { title: { rendered: "" }, content: { rendered: "" } }
      : post;

    return (
      <Grid container className={classes.root}>
        <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <PrintIcon onClick={handlePrint} className={classes.print} />
            )}
          </PrintContextConsumer>
        </ReactToPrint>
        <Post
          classes={classes}
          ref={el => (this.componentRef = el)}
          post={data}
          location={location}
        />
      </Grid>
    );
  }
}

PostDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(PostDetails);
