import genSaga from '../core/saga';
import types from './types';
import actions from './actions';
import { getCategories, createCategory, updateCategory, deleteCategory } from './services';

export default genSaga(
  types,
  {
    get: getCategories,
    create: createCategory,
    update: updateCategory,
    delete: deleteCategory
  },
  actions
);
