import {
  createModelReducer,
  customizeReducer,
  startLoading,
} from "../core/reducer";
import types from "./types";
import initialState from "./initialState";
import { MODEL_OBJS } from "../core/initState";
import { fromJS } from "immutable";

export const USER_MODEL = "user";

const handleLoginSuccess = (state, action) =>
  state.set(MODEL_OBJS, fromJS(action.data));

const jsReducer = customizeReducer(types);
jsReducer[types.API_ADMIN_GET] = startLoading;
jsReducer[types.STORE_OBJ] = handleLoginSuccess;
jsReducer[types.API_ADMIN_LOGOUT] = startLoading;

export default createModelReducer(jsReducer, initialState);
