import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Item from "./Item";
import { Color } from "../../../constants";
import PanelTitle from "../../../components/PanelTitle";
import { getRelatePosts } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/posts/actions";

const styles = {
  root: {
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
    marginTop: 20,
  },
  content: {
    borderTop: 0,
    background: "#ffffff",
    padding: 10,
  },
  item: {
    color: "#0e0e0ede",
    lineHeight: 2,
    fontSize: 15,
    fontWeight: 500,
    width: "100%",
    "&:hover": {
      "& > $icon": {
        color: Color.PRIMARY,
      },
      color: Color.PRIMARY,
    },
  },
  icon: {
    verticalAlign: "sub",
    fontSize: 20,
    marginRight: 5,
    color: "#0e0e0ede",
  },
  text: {
    fontSize: 14,
  },
  corner: {
    width: 30,
    "&:after": {
      borderWidth: "28px 18px",
    },
  },
  time: {
    fontSize: 15,
  },
};

class RelatePosts extends Component {
  render() {
    const { classes, posts, updatePostView } = this.props;
    return (
      <Grid item container className={classes.root}>
        <PanelTitle
          title="TIN LIÊN QUAN"
          classes={{ corner: classes.corner }}
        />
        <Grid container className={classes.content}>
          {posts.map(item => (
            <Item
              key={item.id}
              classes={classes}
              post={item}
              onClick={updatePostView}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
}

RelatePosts.propTypes = {
  posts: [],
  location: "#",
};

RelatePosts.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  posts: PropTypes.arrayOf(Object),
  updatePostView: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  posts: getRelatePosts(state, props),
});

const mapDispatchToProps = dispatch => ({
  updatePostView: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RelatePosts));
