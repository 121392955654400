import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import store from './store';
import App from './apps/app';
import * as serviceWorker from './serviceWorker';
import './index.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4A90E2',
      light: '#E5E5E5',
      dark: '#3b73b4',
    },
  },
  // typography: {
  //   fontFamily: 'inherit',
  //   useNextVariants: true,
  // },
  button: {
    textTransform: 'none !important',
  },
});


export default class Root extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <App />
        </SnackbarProvider>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
