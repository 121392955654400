import genActTypes, { genActType } from '../core/types';

export const API_ADMIN_GET = 'api_admin_get';
export const API_ADMIN_LOGIN = 'api_admin_login';
export const API_ADMIN_LOGOUT = 'api_admin_logout';

const types = genActTypes('user');
types['API_ADMIN_GET'] = genActType('user', API_ADMIN_GET);
types['API_ADMIN_LOGIN'] = genActType('user', API_ADMIN_LOGIN);
types['API_ADMIN_LOGOUT'] = genActType('user', API_ADMIN_LOGOUT);

export default types;
