import {
    take, call, all, put
  } from 'redux-saga/effects';
import { getPosts } from '../../model/posts/services';
import { getHomePostsSuccess, getHomePostsFailed } from './actions';
  import Types from './types'
  
  function* getHomePostsTask() {
    while (true) {
      const { id, categories } = yield take(Types.GET_HOME_POSTS_REQUEST);
      try{
        const posts = yield call(getPosts, categories, 4);
        yield put(getHomePostsSuccess(id, posts))
      } catch(error) {
        yield put(getHomePostsFailed(id, error))
      }
    }
  }
  
  export default function* postsSaga() {
    yield all([getHomePostsTask()]);
  };
  