import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { AlertOptions } from './constants';
import isFunction from 'lodash/isFunction';
import { connect } from 'react-redux';
import { getMsgError, getMsgSuccess } from './selectors';
import { resetNotify } from './actions';

class Notify extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      error, success, reset
    } = this.props;
    if (error && prevProps.error !== error) {
      this.alert(error, AlertOptions.error, reset);
    }
    if (success && prevProps.success !== success) {
      this.alert(success, AlertOptions.success, reset);
    }
  }

  alert = (msg, options, cb) => {
    const { enqueueSnackbar } = this.props;
    const config = Object.assign({}, options, { preventDuplicate: false });
    enqueueSnackbar(msg, config);
    if (isFunction(cb)) {
      cb();
    }
  };

  render() {
    return <React.Fragment />;
  }
}

Notify.defaultProps = {
  error: '',
  success: ''
};

Notify.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  enqueueSnackbar: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  error: getMsgError(state),
  success: getMsgSuccess(state)
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(resetNotify())
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notify));
