import genSelectors from '../core/selectors';
import { MODEL } from '../core/reducer';
import { MEDIA_MODEL } from './reducer';
import { createSelector } from 'reselect';
import { isMapEmpty } from '../../utils/empty';

const selectors = genSelectors([MODEL, MEDIA_MODEL]);

export const getMedia = createSelector(selectors.getObjs, media => (isMapEmpty(media) ? {} : media.toJS()))

export default selectors;
