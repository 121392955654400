import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import PostList from "./PostList";

class Posts extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <Grid container>
        <PostList location={location} />
      </Grid>
    );
  }
}

Posts.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default Posts;
