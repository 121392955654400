import genSelectors from "../core/selectors";
import { MODEL } from "../core/reducer";
import { ACF_MODEL } from "./reducer";
import { createSelector } from "reselect";
import { isMapEmpty } from "../../utils/empty";

const selectors = genSelectors([MODEL, ACF_MODEL]);

export const getAcfs = createSelector(
  selectors.getObjs,
  acfs => (isMapEmpty(acfs) ? {} : acfs.toJS())
);

export default selectors;
