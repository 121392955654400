import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = {
  root: {
    position: "absolute",
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  div: {
    marginTop: 25,
    height: 60,
    width: "100%",
    color: "#000000",
    fontSize: 14,
    lineHeight: "30px",
    textAlign: "center",
    "& span:first-child": {
      fontSize: 24,
    },
  },
};

const Loading = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <img
        alt="loading"
        width="164px"
        height="164px"
        src="https://capnuocthotnot.vn/assets/images/loading.gif"
      />
      <div className={classes.div}>
        <span>Đang tải dữ liệu</span>
        <br />
        <span>Vui lòng chờ...</span>
      </div>
    </div>
  </div>
);

Loading.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Loading);
