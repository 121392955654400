import { put, call, all, take, delay } from 'redux-saga/effects';
import isFunction from 'lodash/isFunction';

export default class SagaBuilder {
  constructor(actions) {
    this.actions = [];
    if (this.valid(actions)) {
      this.actions = this.actions.concat(actions);
    }
  }

  valid = value => Array.isArray(value) || isFunction(value);

  setActions = actions => {
    if (this.valid(actions)) {
      this.actions = this.actions.concat(actions);
    }
  };

  createSaga = ({ type, apiService, successFunc, errorFunc, timeout }) => {
    function* handleSaga() {
      while (true) {
        try {
          const { data: requestData } = yield take(type);
          const data = yield call(apiService, requestData);
          yield delay(timeout);
          yield put(successFunc(data));
        } catch (error) {
          yield put(errorFunc(error));
        }
      }
    }
    return handleSaga;
  };

  add = ({ type, apiService, successFunc, errorFunc, timeout = 0 }) => {
    if (type) {
      const newSaga = this.createSaga({
        type,
        apiService,
        successFunc,
        errorFunc,
        timeout
      });
      this.actions.push(newSaga);
    }
  };

  build = () => {
    const allActions = [];
    this.actions.forEach(action => {
      allActions.push(call(action));
    });
    function* sagaBuilder() {
      yield all(allActions);
    }

    return sagaBuilder;
  };
}
