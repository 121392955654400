import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Images } from "../../../constants";
import { getMediaByKey } from "../../../utils/media";

class PostItem extends React.PureComponent {
  handleClick = () => {
    const { updatePost, post } = this.props;
    updatePost(post.id);
  };

  render() {
    const { classes, post, activeStep, index } = this.props;
    const image = getMediaByKey(post, "full");
    let path = post.path;
    if(path && path !== '/') {
      path = `/${post.path}/${post.slug}`;
    } else {
      path = `/${post.slug}`;
    }
    return (
      <Link
        to={path}
        className={classes.link}
        onClick={this.handleClick}
      >
        {Math.abs(activeStep - index) <= 2 ? (
          <img
            className={classes.img}
            src={image ? image.source_url : Images.AVARTA_POST}
            alt={post.title.rendered}
          />
        ) : null}
      </Link>
    );
  }
}

PostItem.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  activeStep: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
  updatePost: PropTypes.func.isRequired,
};

export default PostItem;
