import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Recaptcha from 'react-recaptcha';
import contactActions from '../../model/contact/actions';
import contactSelectors from '../../model/contact/selectors';

const inputs = [
  {
    id: 'title',
    label: 'Tiêu đề',
    required: true
  },
  {
    id: 'name',
    label: 'Họ và tên',
    required: true
  },
  {
    id: 'email',
    label: 'Email',
    required: true
  },
  {
    id: 'phone',
    label: 'Điện thoại',
    required: true
  },
  {
    id: 'address',
    label: 'Địa chỉ',
    required: false
  }
]

class Feeback extends React.PureComponent {
  state = {
    isVerify: false,
    contact: {
      title: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      content: ''
    }
  }

  handleCancel = () => {
    this.setState({
      contact: {
        title: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        content: ''
      }
    })
  }

  handleClick = () => {
    const { isVerify, contact } = this.state;
    if (isVerify) {
      const { send } = this.props;
      send(contact)
      this.handleCancel();
    }
  }

  handleChange = event => {
    const { id, value } = event.target;
    if (id) {
      this.setState(prevState => ({
        ...prevState,
        contact: {
          ...prevState.contact,
          [id]: value
        }
      }))
    }
  }

  onloadCallback = () => {
    console.log('Done!!!!');
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({ isVerify: true })
    }
  }

  expiredCallback = () => {
    this.setState({ isVerify: false })
  }

  disabled = (contact) => {
    return !contact.title || !contact.name || !contact.email || !contact.phone || !contact.content
  }

  render() {
    const { isVerify, contact } = this.state;
    const { classes } = this.props;
    const disabled = !isVerify || this.disabled(contact);
    return (
      <Grid container className={classes.panel}>
        <Typography className={classNames(classes.panelHeading, classes.feeback)}>
          LIÊN HỆ
        </Typography>
        <Grid item container className={classes.panelContent}>
          {inputs.map(input => (
            <TextField key={input.id} id={input.id} className={classes.textField} variant="outlined" size="small" value={contact[input.id]} onChange={this.handleChange} fullWidth label={input.label} required={input.required} />
          ))}
          <TextField id='content' variant="outlined" label="Nội dung" value={contact.content} onChange={this.handleChange} fullWidth rows={2} required multiline />
          {/* <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Gửi bản sao thư này cho tôi"
          /> */}
          <Grid item container className={classes.captcha}>
            <Recaptcha
              sitekey="6LeGYtEZAAAAAMMSpdxn2eU0YleZBHUc5Kfqoyzr"
              render="explicit"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.onloadCallback}
              expiredCallback={this.expiredCallback}
            />
          </Grid>
          <Grid item container justify="center" className={classes.action}>
            <Button className={classes.button} variant="contained" onClick={this.handleCancel} disableElevation>Nhập lại</Button>
            <Button className={classes.button} variant="contained" color="primary" onClick={this.handleClick} disableElevation disabled={disabled}>Gửi đi</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Feeback.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  send: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isLoading: contactSelectors.isLoading(state)
});

const mapDispatchToProps = dispatch => ({
  send: data => dispatch(contactActions.create(data, 'Feedback'))
});

export default connect(mapStateToProps, mapDispatchToProps)(Feeback);