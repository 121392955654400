import { Map } from "immutable";

export const DATA = 'data';

export const LOADING = 'loading';

export const ERROR = 'error';

const initialState = Map({
  [DATA]: Map(),
  [LOADING]: Map(),
  [ERROR]: Map()
});

export default initialState;