import { List, Map } from 'immutable';
import { MODEL_OBJS, MODEL_LOADING, MODEL_ERROR } from './initState';

/**
 * Get a collection of data model objects in redux.
 *
 * @param {*} state
 * @param {*} paths Paths started from the root
 * @param {*} failover Immutable collection when the collection is not set.
 *
 * @returns Immutable collection of data model objects. Failover or null if not set
 */
export const getObjs = (state, paths = [], failover = null) => state.getIn(paths.concat([MODEL_OBJS]), failover);

/**
 * Get an object by given ID in redux store in case of collection of objects is a map.
 * To get an object in case the collection is a List, use `findObjById` instead.
 *
 * @param {*} state Redux state object.
 * @param {*} paths Paths started from the root
 * @param {*} id Data model object ID.
 * @param {*} failover Immutable collection when the collection is not set.
 *
 * @returns Immutable object if it's found. null otherwise
 */
export const getObjById = (state, paths = [], id, failover = null) => state.getIn(paths.concat([MODEL_OBJS, id]), failover);

const getObjPropValue = (state, paths, id, propName, failover) => {
  const obj = getObjById(state, paths, id, Map());
  return obj.get(propName, failover);
};

/**
 * Find an object by given ID in redux store in case of collection of objects is a list.
 * To get an object in case the collection is a Map, use `getObjById` instead.
 *
 * @param {*} state Redux state object.
 * @param {*} paths Paths started from the root
 * @param {*} id Data model object ID.
 * @param {*} failover Immutable collection when the collection is not set.
 * @param {*} propName ID property name. Default to `id`.
 *
 * @returns Immutable object if it's found. null otherwise
 */
const findObjById = (state, paths = [], id, failover = null, propName = 'id') => {
  const objs = getObjs(state, paths);
  if (List.isList(objs)) {
    return objs.find(obj => obj.get(propName) === id) || failover;
  }
  return failover;
};

const getObjByPath = (state, paths = [], path, failover = null) => {
  const objs = getObjs(state, paths);
  if (objs) {
    return objs.getIn(path, failover);
  }
  return failover;
};

/**
 * Base selectors to customize.
 * It's used for a module wanting to customize base selectors.
 * If not customizing selectors, use exported base `selectors` instead for perfomance.
 */
const genSelectors = (paths = []) => ({
  getObjs: (state, failover = null) => getObjs(state, paths, failover),
  getObjById: (state, id, failover = null) => getObjById(state, paths, id, failover),
  getObjPropValue: (state, id, propName, failover = null) => getObjPropValue(state, paths, id, propName, failover),
  findObjById: (state, id, failover = null) => findObjById(state, paths, id, failover),
  isLoading: state => state.getIn(paths.concat([MODEL_LOADING])),
  getObjByPath: (state, path, failover = null) => getObjByPath(state, paths, path, failover),
  getApiError: state => state.getIn(paths.concat([MODEL_ERROR]))
});

export default genSelectors;
export const selectors = genSelectors();
