import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Modules from "../../../components/Modules";
import Posts from "./Posts";
import { CategoryPks, RoutePaths } from "../../../constants";
import Table from "../../table";

const styles = {
  root: {
    padding: 8,
  },
  wrapper: {
    marginBottom: 12,
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
  },
  popularPost: {
    overflowY: "auto",
    border: "1px solid #3498db",
    borderTop: 0,
    padding: 5,
    background: "#ffffff",
  },
  img: {
    width: 90,
    height: 60,
  },
  divider: {
    width: "100%",
  },
  title: {
    fontWeight: "normal",
    color: "#000000",
  },
};

class RightPanel extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={4} className={classes.root}>
        <Posts
          id={CategoryPks["hoat-dong"]}
          classes={classes}
          title="HOẠT ĐỘNG"
          path={RoutePaths.HOAT_DONG}
        />
        <Grid item container className={classes.wrapper}>
          <Modules />
        </Grid>
        <Grid item container className={classes.wrapper}>
          <Table />
        </Grid>
        <Posts
          id={CategoryPks["san-pham-va-dich-vu"]}
          classes={classes}
          title="SẢN PHẨM DỊCH VỤ"
          path={RoutePaths.SAN_PHAN_VA_DICH_VU}
        />
      </Grid>
    );
  }
}

RightPanel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(RightPanel);
