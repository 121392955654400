import genSaga from "../core/saga";
import types from "./types";
import actions from "./actions";
import { getTags } from "./services";

export default genSaga(
  types,
  {
    get: getTags,
  },
  actions
);
