import React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { EMPTY } from "../../../constants";
import { makeGetCategoryPath } from "../../../model/categories/selectors";

class PostTitle extends React.Component {
  render() {
    const { classes, post, path } = this.props;
    return (
      <Link to={`${path}/${post.path}`}>
        <Paper square elevation={0} className={classes.header}>
          <Typography noWrap className={classes.title}>
            {post.title.rendered || EMPTY}
          </Typography>
        </Paper>
      </Link>
    );
  }
}

PostTitle.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
  path: PropTypes.string.isRequired,
};

const makeMapStateToProps = () => {
  const getCatPath = makeGetCategoryPath();
  const mapStateToProps = (state, props) => ({
    path: getCatPath(state, props),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(PostTitle);
