import store from '../store';
import categoriesAction from '../model/categories/actions';
import { getCategories } from '../model/categories/services';

const categoriesTask = () => getCategories().then((categories) => {
  store.dispatch(categoriesAction.storeAll(categories));
  return categories;
});

export default categoriesTask;
