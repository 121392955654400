import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PostNews from './PostNews';
import { CategoryPks } from '../../../constants';

const styles = {
  item: {
    marginTop: 10
  },
  corner: {
    width: 30,
    '&:after': {
      borderWidth: '20px 10px'
    }
  },
  itemWrapper: {
    paddingTop: 14
  },
  text: {
    maxWidth: 305
  }
}

const postNews = [
  {
    id: CategoryPks["tin-hoat-dong-cong-ty"],
    title: "TIN HOẠT ĐỘNG CÔNG TY",
    path: '/tin-tuc/tin-hoat-dong-cong-ty'
  },
  {
    id: CategoryPks["tin-khoa-hoc-cong-nghe"],
    title: "TIN KHOA HỌC - CÔNG NGHỆ",
    path: '/tin-tuc/tin-khoa-hoc-cong-nghe'
  },
  {
    id: CategoryPks["tin-dia-phuong"],
    title: "TIN ĐỊA PHƯƠNG",
    path: '/tin-tuc/tin-dia-phuong'
  },
  {
    id: CategoryPks["diem-tin-nganh-nuoc"],
    title: "ĐIỂM TIN NGÀNH NƯỚC",
    path: '/tin-tuc/diem-tin-nganh-nuoc'
  },
  {
    id: CategoryPks["giao-luu-voi-tinh-ban"],
    title: "GIAO LƯU VỚI TỈNH BẠN",
    path: '/tin-tuc/giao-luu-voi-tinh-ban'
  },
  {
    id: CategoryPks["quan-he-co-dong"],
    title: "QUAN HỆ CỔ ĐÔNG",
    path: '/quan-he-co-dong'
  }
]

class News extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {postNews.map(item => (
          <Grid key={item.id} item container className={classes.item}>
            <PostNews id={item.id} classes={{ corner: classes.corner, itemWrapper: classes.itemWrapper, text: classes.text }} title={item.title} path={item.path} />
          </Grid>
        ))}
      </Grid>
    )
  }
}

News.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
}

export default withStyles(styles)(News);