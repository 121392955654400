import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import News from './News';
import Activity from '../activity'
import Modules from '../../../components/Modules';
import PostNews from '../news';
import Table from '../../table';
import Service from '../service'
import { getCatPk } from '../../../utils/category';
import { EMPTY, CategoryPks } from '../../../constants';
import Posts from '../posts';
import Support from '../../support';

const styles = {
  modules: {
    marginTop: 10,
    borderRadius: 0
  },
  table: {
    marginTop: 10
  }
}

class Home extends React.PureComponent {
  render() {
    const { classes, location } = this.props;
    const catPk = getCatPk(location);
    if (catPk === CategoryPks["tin-tuc"]) {
      return (
        <Grid container>
          <News />
          <Support />
          <Activity />
          <Modules classes={{ root: classes.modules }} />
          <PostNews />
          <Table classes={{ root: classes.table }} />
          <Service />
        </Grid>
      )
    }
    return <Posts location={location} />
  }
}

Home.defaultProps = {
  location: {
    pathname: EMPTY
  }
}

Home.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
}

export default withStyles(styles)(Home);