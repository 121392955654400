import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PanelTitle from "./PanelTitle";
import PopularPost from "./PopularPost";
import LinkPost from "./LinkPost";
import { Color } from "../constants";

const styles = {
  root: {
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
  },
  news: {
    borderTop: 0,
    background: "#ffffff",
    paddingBottom: 15,
  },
  post: {
    padding: "5px 15px",
  },
  itemWrapper: {
    padding: "5px 5px 0px",
  },
  img: {
    height: 70,
    width: 110,
    border: "1px solid #dfdfdf",
  },
  popular: {
    height: 60,
  },
  item: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  icon: {
    color: "#BF3E00",
  },
  link: {
    color: "#171717",
    "&:hover": {
      color: Color.PRIMARY,
    },
  },
  text: {
    maxWidth: 360,
    fontSize: 15,
  },
  corner: {},
};

class PostNews extends Component {
  render() {
    const { classes, posts, title, path, onClick } = this.props;
    const [firstPost] = posts;
    return (
      <Grid item container className={classes.root}>
        <PanelTitle
          classes={{ corner: classes.corner }}
          title={title}
          linkTo={path}
        />
        <Grid container className={classes.news}>
          <Grid item container className={classes.post}>
            {firstPost && (
              <PopularPost
                classes={{ root: classes.popular, img: classes.img }}
                post={firstPost}
                onClick={onClick}
              />
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.itemWrapper}
          >
            {posts.map((post, index) => {
              if (index > 0 && index < 3) {
                return (
                  <LinkPost
                    key={post.id}
                    classes={classes}
                    post={post}
                    onClick={onClick}
                  />
                );
              }
              return <React.Fragment key={post.id} />;
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

PostNews.propTypes = {
  posts: [],
  onClick: () => {},
};

PostNews.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  post: PropTypes.arrayOf(Object),
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(PostNews);
