import { connect } from "react-redux";
import PostNews from "../../../components/PostNews";
import { makeGetPostsByCatPk } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/post_views/actions";

const makeMapStateToProps = () => {
  const getPosts = makeGetPostsByCatPk();
  const mapStateToProps = (state, props) => ({
    posts: getPosts(state, props),
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onClick: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(PostNews);
