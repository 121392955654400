import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import { Layout } from '../../../constants';
import Menu from '../menu';
import Content from './Content';

const styles = {
  root: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    display: 'flex',
    background: '#f0f0f0',
    justifyContent: 'center'
  },
  container: {
    height: 'calc(100% - 273px)',
    maxWidth: Layout.MAX_WIDTH,
    paddingBottom: 19
  },
  contentWrapper: {
    minHeight: 'calc(100vh - 513px)'
  },
  content: {
    paddingRight: 15
  }
};

class App extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Router>
          <Grid container className={classes.wrapper}>
            <Header />
            <Grid item container className={classes.container}>
              <Menu />
              <Grid item container className={classes.contentWrapper}>
                <Switch>
                  <Content classes={classes} />
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Router>
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
}

export default withStyles(styles)(App);

