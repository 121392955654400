import request from "../../services/request";
import { formatDate } from "../../utils/date";
import { isEmpty } from "lodash";

const url = "wp-json/wp/v2/posts";

export const createPost = async data =>
  request.post("/api/post/create_post", data).then(({ data }) => data);

export const updatePost = async data =>
  request
    .put("/api/post/update_post", data)
    .then(({ data }) => ({ ...data, orgId: data.path }));

export const getPost = async path =>
  request.get(`/api/v1/post/get_post_details/${path}`).then(res => {
    let { data } = res;
    if (!data) {
      return {};
    }
    data.created_at = data.created_at
      ? formatDate(data.created_at, true)
      : null;
    return data;
  });

export const getPosts = async ({
  categories,
  page,
  limit,
  search,
  isSearch,
  tagId,
}) => {
  let parameters = [];
  if (Array.isArray(categories) && categories.length > 0) {
    parameters.push(`categories=${categories.join(",")}`);
  }
  if (page > 0) {
    parameters.push(`page=${page}`);
  }
  if (limit > 0) {
    parameters.push(`per_page=${limit}`);
  }
  if (search) {
    parameters.push(`search=${search}`);
  }
  if (tagId) {
    parameters.push(`tags=${tagId}`);
  }
  const postUrl = isEmpty(parameters) ? url : `${url}?${parameters.join("&")}`;
  return request.get(postUrl).then(res => {
    const { data } = res;
    if (isEmpty(data)) {
      return [];
    }
    if (isSearch) {
      return data.map(item => ({ ...item, isSearch: true }));
    }
    return data;
  });
};

export default {};
