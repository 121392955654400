import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Color, RoutePaths, EMPTY } from "../../../constants";
import postsActions from "../../../model/posts/actions";

const styles = theme => ({
  root: {
    padding: "2px 0px 2px 4px",
    display: "flex",
    alignItems: "center",
    width: 200,
    height: 20,
    marginRight: 10,
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 13,
  },
  divider: {
    height: 20,
    marginLeft: 6,
  },
  icon: {
    fontSize: 18,
    cursor: "pointer",
    marginRight: 5,
    marginLeft: 5,
    color: "#c2c2c2",
    "&:hover": {
      color: Color.PRIMARY,
    },
  },
  link: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
});

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: EMPTY,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(event) {
    this.setState({ searchValue: event.target.value });
  }

  handleSearch() {
    const { searchValue } = this.state;
    const { search } = this.props;
    search({ search: searchValue, limit: 100, isSearch: true });
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} elevation={0}>
        <InputBase
          className={classes.input}
          placeholder="Search..."
          inputProps={{ "aria-label": "search..." }}
          onChange={this.handleChange}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <Link
          to={RoutePaths.SEARCH}
          className={classes.link}
          onClick={this.handleSearch}
        >
          <SearchIcon className={classes.icon} />
        </Link>
      </Paper>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  search: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  search: data => dispatch(postsActions.get(null, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(Search));
