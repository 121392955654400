import { Map } from 'immutable';
import { EMPTY } from '../../constants';
import { Types } from './Types';
import createReducer from '../../utils/createReducer';
import postTypes from '../../model/posts/types';
import catTypes from '../../model/categories/types';
import contactTypes from '../../model/contact/types';

export const NOTIFY = 'notify';

const initialState = Map({
  success: EMPTY,
  error: EMPTY
});

const notify = (state, type, msg) => state.set(type, msg);

const handleSuccess = state => notify(state, 'success', 'Save successfully');

const handleError = (state, action) => {
  const { error = {} } = action;
  const { response = {} } = error;
  const { data = {} } = response;
  const errMsg = data.message;
  return notify(state, 'error', errMsg);
};

const handleReset = state => state.set('success', EMPTY).set('error', EMPTY);

// Post
const handleCreatePostSuccess = state => notify(state, 'success', 'Thêm bài viết thành công');
const handleUpdatePostSuccess = state => notify(state, 'success', 'Cập nhật bài viết thành công');

// Category
const handleCreateCatSuccess = state => notify(state, 'success', 'Thêm chuyên mục thành công');
const handleUpdateCatSuccess = state => notify(state, 'success', 'Cập nhật chuyên mục thành công');
const handleRemoveCatSuccess = state => notify(state, 'success', 'Xóa chuyên mục thành công');

const handleFeedbackSuccess = (state, action) => notify(state, 'success', action.data.msg);


export default createReducer(initialState, {
  [Types.NOTIFY_SUCCESS]: handleSuccess,
  [Types.NOTIFY_ERROR]: handleError,
  [Types.NOTIFY_RESET]: handleReset,

  // Post
  [postTypes.STORE_OBJ]: handleCreatePostSuccess,
  [postTypes.UPDATE_OBJ]: handleUpdatePostSuccess,
  [postTypes.API_FAILED]: handleError,

  // Category
  [catTypes.STORE_OBJ]: handleCreateCatSuccess,
  [catTypes.UPDATE_OBJ]: handleUpdateCatSuccess,
  [catTypes.REMOVE_OBJ]: handleRemoveCatSuccess,
  [catTypes.API_FAILED]: handleError,

  [contactTypes.STORE_OBJ]: handleFeedbackSuccess,
  [contactTypes.API_FAILED]: handleError,
});
