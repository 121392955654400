export const Layout = {
  MAX_WIDTH: 1280,
};

export const Images = {
  HEADER: "https://capnuocthotnot.vn/assets/images/header.jpg",
  QUAN_HE_CO_DONG: "https://capnuocthotnot.vn/assets/images/qhcd.png",
  THANH_TOAN_NGAN_HANG: "https://capnuocthotnot.vn/assets/images/ttnh.png",
  AP_LUC_LUU_LUONG: "https://capnuocthotnot.vn/assets/images/aplucluuluong.png",
  KET_QUA_MAU_NUOC: "https://capnuocthotnot.vn/assets/images/ketquamaunuoc.png",
  AVARTA_POST: "https://capnuocthotnot.vn/assets/images/logo.jpg",
  FOOTER: "https://capnuocthotnot.vn/assets/images/bg_footer.png",
  BAO_CAO_SU_CO:
    "https://capnuocthotnot.vn/assets/images/uploads/bao_cao_su_co.jpg",
  TRA_CUU_HOA_DON_DIEN_TU:
    "https://capnuocthotnot.vn/assets/images/uploads/tra_cuu_hoa_don_dien_tu.jpg",
};

export const Color = {
  PRIMARY: "#3498db",
  DARK_PRIMARY: "#1379b1",
};

export const ReduxName = {
  MODEL: "model",
  POST_REDUCER: "postReducer",
};

export const RoutePaths = {
  HOME: "/",
  GIOI_THIEU: "/gioi-thieu",
  SO_LUOC: "/so-luoc-ve-cong-ty",
  TO_CHUC: "/he-thong-to-chuc",
  TIN_TUC: "/tin-tuc",
  HOAT_DONG_CTY: "/tin-hoat-dong-cong-ty",
  KHOA_HOC_CONG_NGHE: "/tin-khoa-hoc-cong-nghe",
  TIN_DIA_PHUONG: "/tin-dia-phuong",
  DIEM_TIN_NGANH_NUOC: "/diem-tin-nganh-nuoc",
  GIAO_LUU: "/giao-luu-voi-tinh-ban",
  HOI_THAO_HOI_THAO: "/hoi-thao-hoi-thao",
  QUAN_HE_CO_DONG: "/quan-he-co-dong",
  DIEU_LE_QUY_CHE: "/dieu-le-quy-che",
  BAO_CAO_DINH_KY: "/bao-cao-dinh-ky",
  THONG_BAO_CO_DONG: "/thong-bao-co-dong",
  DAI_HOI_DONG_CO_DONG: "/dai-hoi-dong-co-dong",
  BAO_CAO_TAI_CHINH: "/bao-cao-tai-chinh",
  SAN_PHAN_VA_DICH_VU: "/san-pham-va-dich-vu",
  DICH_VU_CAP_NUOC: "/dich-vu-cap-nuoc",
  DICH_VU_THOAT_NUOC: "/dich-vu-thoat-nuoc",
  HO_TRO_KHACH_HANG: "/ho-tro-khach-hang",
  THONG_BAO: "/thong-bao",
  CAC_DICH_VU_CAP_NUOC: "/cac-dich-vu-cap-nuoc",
  KIEN_THUC_NGANH_NUOC: "/kien-thuc-nganh-nuoc",
  HOAT_DONG: "/hoat-dong",
  THUC_HIEN_QUY_CHE_DC: "/thuc-hien-quy-che-dan-chu",
  CONG_TAC_PHONG_CHONG_THAT_THOAT: "/cong-tac-chong-that-thoat",
  VAN_NGHE: "/van-hoa-van-nghe-the-thao",
  PHONG_CHAY_CHUA_CHAY: "/phong-chay-chua-chay",
  CONG_DOAN: "/cong-doan-doan-thanh-nien",
  VAN_BAN_PHAP_LUAT: "/van-ban-phap-luat",
  HOA_DON_DIEN_TU: "/hoa-don-dien-tu",
  LIEN_HE: "/lien-he",
  HOI_DAP: "/hoi-dap",
  GIA_NUOC: "/gia-tieu-thu-nuoc-sach",
  ADMIN: "/quan-tri",
  ADMIN_POST_LIST: "/quan-tri/tat-ca-bai-viet",
  ADMIN_POST: "/quan-tri/bai-viet",
  SEARCH: "/tim-kiem",
  CHAT_LUONG_NUOC: "/ket-qua-mau-nuoc",
  // CHAT_LUONG_NUOC: '/chat-luong-nuoc'
};

export const CategoryPks = {
  "": 5,
  "gioi-thieu": 2,
  "so-luoc-ve-cong-ty": 3,
  "he-thong-to-chuc": 4,
  "tin-tuc": 5,
  "tin-hoat-dong-cong-ty": 6,
  "tin-khoa-hoc-cong-nghe": 7,
  "tin-dia-phuong": 8,
  "diem-tin-nganh-nuoc": 9,
  "giao-luu-voi-tinh-ban": 10,
  "hoi-thao-hoi-thao": 11,
  "quan-he-co-dong": 12,
  "dieu-le-quy-che": 13,
  "bao-cao-dinh-ky": 14,
  "thong-bao-co-dong": 15,
  "dai-hoi-dong-co-dong": 16,
  "bao-cao-tai-chinh": 17,
  "san-pham-va-dich-vu": 18,
  "dich-vu-cap-nuoc": 19,

  "ho-tro-khach-hang": 20,
  "thong-bao": 21,
  "cac-dich-vu-cap-nuoc": 22,
  "kien-thuc-nganh-nuoc": 23,
  "hoat-dong": 24,
  "thuc-hien-quy-che-dan-chu": 25,
  "cong-tac-chong-that-thoat": 26,
  "van-hoa-van-nghe-the-thao": 27,
  "phong-chay-chua-chay": 28,
  "cong-doan-doan-thanh-nien": 29,
  "van-ban-phap-luat": 30,
  "hoa-don-dien-tu": 31,
  "lien-he": 32,
  "hoi-dap": 33,
  "ket-qua-mau-nuoc": 34,
  "gia-nuoc-sach": 35,
};

export const ActionTypes = {
  SELECT_MENU_ITEM: "SELECT_MENU_ITEM",
  SELECT_CATEGORY_PK: "SELECT_CATEGORY_PK",
  SELECT_CATEGORY_PKS: "SELECT_CATEGORY_PKS",
  UPLOAD_THUMBNAIL_REQUEST: "UPLOAD_THUMBNAIL_REQUEST",
  UPLOAD_THUMBNAIL_SUCCESS: "UPLOAD_THUMBNAIL_SUCCESS",
  UPLOAD_THUMBNAIL_FAILED: "UPLOAD_THUMBNAIL_FAILED",
  CLOSE_ALERT: "CLOSE_ALERT",

  TASKS_RUN: "TASKS_RUN",
  TASKS_FINISHED: "TASKS_FINISHED",

  POSTS_SEARCH_REQUEST: "POSTS_SEARCH_REQUEST",
  POSTS_SEARCH_SUCCESS: "POSTS_SEARCH_SUCCESS",
  POSTS_SEARCH_FAILED: "POSTS_SEARCH_FAILED",
};

export const MenuActions = {
  UPDATE: "update",
  DELETE: "delete",
};

export const SLASH = "/";

export const EMPTY = "";

export const DASH = "-";

export const COMMA_SPACE = ", ";

export const SPACE = " ";

export default {};
