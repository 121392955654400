import React, { PureComponent } from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SubMenu from "./SubMenu";
import { EMPTY } from "../../../../constants";

class MenuItem extends PureComponent {
  render() {
    const { classes, item, selected, onClick } = this.props;
    const { id, path, name, children } = item;
    return (
      <Tooltip
        title={children && children.length > 0 ? <SubMenu data={children} parentId={id} onClick={onClick} /> : EMPTY}
        arrow
        interactive
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow, tooltipPlacementBottom: classes.placementBottom }}>
        <Button id={id} className={classNames(classes.button, { [classes.btnActive]: selected })} onClick={onClick}>
          <Link to={path} className={classes.link}>{name}</Link>
        </Button>
      </Tooltip>
    );
  }
}

MenuItem.defaultProps = {
  selected: false
}

MenuItem.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default MenuItem;