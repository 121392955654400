import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import PropTypes from "prop-types";
import PostItem from "./PostItem";
import PostTitle from "./PostTitle";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // padding: 10,
    position: "relative",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    position: "absolute",
    left: 0,
    bottom: 0,
    color: "#ffffff",
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    backgroundColor: "#0000009e",
  },
  img: {
    height: 260,
    display: "block",
    overflow: "hidden",
    width: "100%",
    border: "1px solid #dfdfdf",
  },
  title: {
    fontSize: 14,
    padding: "5px 10px",
    lineHeight: 1.3,
    fontWeight: "bold",
  },
}));

function PostSlider({ posts }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const isEmpty = !posts || (posts && posts.length < 1);
  const post = posts[activeStep];

  return (
    <div className={classes.root}>
      {post && <PostTitle classes={classes} post={post} />}
      {!isEmpty && (
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {posts.map((post, index) => {
            return (
              <PostItem
                key={post.id}
                classes={classes}
                post={post}
                activeStep={activeStep}
                index={index}
              />
            );
          })}
        </AutoPlaySwipeableViews>
      )}
    </div>
  );
}

PostSlider.defaultProps = {
  posts: [],
};

PostSlider.propTypes = {
  posts: PropTypes.arrayOf(Object),
};

export default PostSlider;
