import initialState, { MENU_ID } from "./initialState";
import createReducer from '../../../utils/createReducer';
import { ActionTypes } from "../../../constants";

export const MENU = 'menu';

export const handleSelectMenuItem = (state, action) => state.set(MENU_ID, action.id);

export default createReducer(initialState, {
  [ActionTypes.SELECT_MENU_ITEM]: handleSelectMenuItem
});