import { fromJS } from 'immutable';

export const MODEL_OBJS = 'objects';
export const MODEL_LOADING = 'loading';
export const MODEL_ERROR = 'err';

/**
 * Generate an Immutable object for initial state of a redux data model.
 */
const genInitState = () =>
  fromJS({
    /**
     * Collection of data model.
     * It can be a Immutable Map or List depending on the response of a API service of a module.
     */
    [MODEL_OBJS]: null,

    /**
     * Loading status when an API call is in progress or not.
     * It's used freely for any kind of API services (GET, PUT, POST, DELETE)
     */
    [MODEL_LOADING]: false,

    /**
     * Last API error.
     * It's used to store the most recent API call error
     */
    [MODEL_ERROR]: null
  });

export default genInitState;
