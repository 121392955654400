import { Color, Images } from "../../../../constants";

const styles = {
  root: {
    height: 192,
    marginBottom: 8
  },
  banel: {
    height: 'calc(100% - 44px)'
  },
  menu: {
    height: 44,
    background: Color.PRIMARY,
    alignItems: 'center',
    color: '#ffffff'
  },
  menuGroup: {
    height: '100%',
    boxShadow: 'none'
  },
  divider: {
    '&:not(:last-child)': {
      borderRight: `none`,
    }
  },
  button: {
    borderRadius: 0,
    background: Color.PRIMARY,
    color: '#ffffff',
    fontSize: '13px !important',
    padding: '6px 10px',
    height: '100%',
    borderLeft: '1px solid #1379b1',
    '&:hover': {
      boxShadow: 'none',
      background: Color.DARK_PRIMARY
    }
  },
  btnActive: {
    background: Color.DARK_PRIMARY
  },
  img: {
    height: '100%',
    width: '100%',
    background: `url(${Images.HEADER}) no-repeat`,
    backgroundSize: 'cover'
  },
  icon: {
    fontSize: '1.8rem',
    verticalAlign: 'sub'
  },
  tooltip: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    backgroundColor: '#ffffff',
    color: '#000000',
    padding: 0,
    minWidth: 180
  },
  arrow: {
    color: '#ffffff'
  },
  placementBottom: {
    margin: 0
  },
  link: {
    color: '#ffffff',
    fontWeight: 'bold'
  },
  home: {
    width: 45,
    color: '#ffffff',
    textAlign: 'center'
  }
}

export default styles;