import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Images, RoutePaths } from "../../constants";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: "Báo cáo sự cố",
    imgPath: Images.BAO_CAO_SU_CO,
    path: "#",
  },
  {
    label: "Tra cứu hoá đơn điện tử",
    imgPath: Images.TRA_CUU_HOA_DON_DIEN_TU,
    path: RoutePaths.HOA_DON_DIEN_TU,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  img: {
    height: 180,
    display: "block",
    overflow: "hidden",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
}));

function Support() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Link to={step.path}>
                <img
                  className={classes.img}
                  src={step.imgPath}
                  alt={step.label}
                />
              </Link>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </div>
  );
}

export default Support;
