import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { isEmpty } from "lodash";
import Tags from "../apps/tags";

class Post extends React.PureComponent {
  render() {
    const { classes, post, location } = this.props;
    const data = isEmpty(post)
      ? { title: { rendered: "" }, content: { rendered: "" } }
      : post;

    return (
      <div className={`page-break ${classes.container}`}>
        <Typography component="div" className={classes.title}>
          {data.title.rendered}
        </Typography>
        {data.createdAt && (
          <Typography className={classes.info} noWrap>
            <DateRangeIcon className={classes.icon} />
            {data.createdAt}
          </Typography>
        )}
        <div
          id="post"
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: data.content.rendered || "" }}
        />
        {post.tags && post.tags.length > 0 && (
          <Tags tags={post.tags} location={location} />
        )}
      </div>
    );
  }
}

Post.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
};

export default Post;
