import { fade } from '@material-ui/core/styles';

const drawerWidth = 300;

const styles = (theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#2980b9'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  container: {
    // height: '100vh',
    flexGrow: 1,
    background: '#f0f0f0',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    padding: 10,
    minHeight: 248
  },
  home: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 12px 5px',
    textTransform: 'uppercase',
    fontSize: 14,
    color: '#313131',
    cursor: 'pointer'
  },
  homeIcon: {
    marginRight: 5
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: 40,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
    cursor: 'pointer',
    borderRadius: '0px 4px 4px 0px',
    backgroundColor: 'rgb(191, 191, 191)',
    color: '#ffffff'
  },
  inputRoot: {
    color: 'inherit',
    width: 'calc(100% - 40px)'
  },
  inputInput: {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    width: '100%',
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: '4px 0px 0px 4px',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default styles;