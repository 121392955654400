import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles'
import { Color } from '../../../../constants';

const styles = {
  root: {
    width: '100%',
    fontSize: 15
  },
  itemRoot: {
    padding: '2px 10px',
    '&:hover': {
      background: Color.PRIMARY,
      color: '#ffffff'
    }
  },
  link: {
    color: '#000000'
  },
  primary: {
    fontSize: 15
  }
}

class SubMenu extends React.PureComponent {
  handleClick = () => {
    const { onClick, parentId } = this.props;
    onClick({ currentTarget: { id: parentId } })
  }
  render() {
    const { classes, data } = this.props;
    return (
      <Grid container>
        <List component="nav" className={classes.root}>
          {data.map(item => (
            <Link key={item.id} to={item.path} className={classes.link}>
              <ListItem button className={classes.itemRoot} onClick={this.handleClick}>
                <ListItemText classes={{ primary: classes.primary }} primary={item.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Grid>
    )
  }
}

SubMenu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  parentId: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(SubMenu);