import genSaga from '../core/saga';
import types from './types';
import actions from './actions';
import { getPosts, createPost, updatePost, getPost } from './services';

export default genSaga(
  types,
  {
    get: getPosts,
    findOne: getPost,
    create: createPost,
    update: updatePost
  },
  actions
);
