import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PanelTitle from "../../../components/PanelTitle";
import Item from "./Item";
import { Color } from "../../../constants";
import { getRelatePosts } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/post_views/actions";

const styles = {
  root: {
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
    marginTop: 20,
  },
  content: {
    borderTop: 0,
    background: "#ffffff",
    padding: 10,
  },
  item: {
    color: "#0e0e0ede",
    lineHeight: 2,
    fontSize: 15,
    fontWeight: 500,
    width: "100%",
    "&:hover": {
      "& > $icon": {
        color: Color.PRIMARY,
      },
      color: Color.PRIMARY,
    },
  },
  icon: {
    verticalAlign: "sub",
    fontSize: 20,
    marginRight: 5,
    color: "#0e0e0ede",
  },
  time: {
    fontSize: 15,
  },
};

class RelatePosts extends Component {
  render() {
    const { classes, posts, onClick } = this.props;
    return (
      <Grid item container className={classes.root}>
        <PanelTitle title="TIN LIÊN QUAN" />
        <Grid container className={classes.content}>
          {posts.map(post => (
            <Item
              key={post.id}
              classes={classes}
              item={post}
              onClick={onClick}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
}

RelatePosts.propTypes = {
  posts: [],
};

RelatePosts.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  posts: PropTypes.arrayOf(Object),
};

const mapStateToProps = (state, props) => ({
  posts: getRelatePosts(state, props),
});

const mapDispatchToProps = dispatch => ({
  onClick: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RelatePosts));
