import { fromJS, Map, List } from "immutable";
import createReducer from "../../utils/createReducer";
import { isListEmpty } from "../../utils/empty";
import { MODEL_LOADING, MODEL_ERROR, MODEL_OBJS } from "./initState";

export const MODEL = "model";

export const validateObj = obj => obj && (obj.id || obj.pk);

export const startLoading = state =>
  state.set(MODEL_LOADING, true).set(MODEL_ERROR, null);

export const stopLoading = (state, err) =>
  state.set(MODEL_LOADING, false).set(MODEL_ERROR, err);

export const storeObjs = (state, objs) => {
  const updatedState = stopLoading(state);

  if (!objs) return updatedState;

  const imObjs = fromJS(objs);
  if (List.isList(imObjs)) {
    let data = updatedState.get(MODEL_OBJS) || List();
    if (isListEmpty(data)) {
      return updatedState.set(MODEL_OBJS, imObjs);
    }
    for (let i = 0; i < imObjs.size; i += 1) {
      const id = imObjs.getIn([i, "id"]);
      const index = data.findIndex(el => el.get("id") === id);
      data =
        index < 0 ? data.push(imObjs.get(i)) : data.set(index, imObjs.get(i));
    }
    return updatedState.set(MODEL_OBJS, data);
  }
  const data = updatedState.get(MODEL_OBJS) || Map();
  return updatedState.set(MODEL_OBJS, data.merge(imObjs));
};

export const storeObj = (state, obj) => {
  const updatedState = stopLoading(state);

  if (!validateObj(obj)) return updatedState;

  let objs = state.get(MODEL_OBJS);
  const objId = obj.id || obj.pk;
  const immObj = fromJS(obj);

  if (Map.isMap(objs)) {
    objs = (objs || Map()).set(`${objId}`, immObj);
  } else {
    objs = (objs || List()).push(immObj);
  }

  return updatedState.set(MODEL_OBJS, objs);
};

// Currently, API does not response createdAt value even passing it to the request
const updateCreatedAt = (newImmO, oldImmO) =>
  newImmO.set("created_at", oldImmO.get("created_at"));

export const updateObj = (state, obj) => {
  const updatedState = stopLoading(state);

  if (!validateObj(obj)) return updatedState;

  let objs = updatedState.get(MODEL_OBJS);
  const objId = obj.orgId || obj.id || obj.pk;
  const id = `${objId}`;

  let oldImmObj;
  let immObj = fromJS(obj);
  if (Map.isMap(objs)) {
    oldImmObj = objs.get(id);
    immObj = updateCreatedAt(immObj, oldImmObj);
    objs = objs.set(id, immObj);
  } else {
    const idx = objs.findIndex(o => o.get("id") === id || o.get("pk") === id);
    if (idx >= 0) {
      oldImmObj = objs.get(idx);
      immObj = updateCreatedAt(immObj, oldImmObj);
      objs = objs.set(idx, immObj);
    }
  }

  return updatedState.set(MODEL_OBJS, objs);
};

const removeObjById = (data, id) => {
  let objs = data;
  if (Map.isMap(objs)) {
    objs = objs.delete(id);
  } else {
    const idx = objs.findIndex(o => o.get("id") === id || o.get("pk") === id);
    if (idx >= 0) objs = objs.delete(idx);
  }
  return objs;
};

const removeObj = (state, objId) => {
  const updatedState = stopLoading(state);
  if (!objId) return updatedState;
  let objs = updatedState.get(MODEL_OBJS);
  if (Array.isArray(objId)) {
    objId.forEach(id => {
      objs = removeObjById(objs, `${id}`);
    });
    return updatedState.set(MODEL_OBJS, objs);
  }
  objs = removeObjById(objs, `${objId}`);
  return updatedState.set(MODEL_OBJS, objs);
};

export const createModelReducer = (customReducers, initState) =>
  createReducer(initState, customReducers);

const clearApiError = state => state.set(MODEL_ERROR, null);

/**
 * Generate a reducer for a data model to customize before registering to redux store.
 * It's only a JS object so that a data model can customize if needed.
 * Before registering to redux store, use `createReducer` from 'utils/createReducer' and pass in this object.
 *
 * @param {*} T Action types for data model which generated by `./types.js`
 */
export const customizeReducer = T => ({
  [T.API_GET]: state => startLoading(state),
  [T.API_FIND_ONE]: state => startLoading(state),
  [T.API_CREATE]: state => startLoading(state),
  [T.API_UPDATE]: state => startLoading(state),
  [T.API_DELETE]: state => startLoading(state),
  [T.API_FAILED]: (state, { error }) => stopLoading(state, error),

  [T.STORE_ALL]: (state, { data }) => storeObjs(state, data),
  [T.STORE_OBJ]: (state, { data }) => storeObj(state, data),
  [T.UPDATE_OBJ]: (state, { data }) => updateObj(state, data),
  [T.REMOVE_OBJ]: (state, { data }) => removeObj(state, data),

  [T.CLEAR_ERROR]: state => clearApiError(state),
});

/**
 * Generate a reducer for a data model which can be combined to a redux store.
 * To customize a reducer before registering to redux, use `customizeReducer` instead.
 *
 * @param {*} types Action types for data model which generated by `./types.js`
 * @param {*} initState Initial state of model
 */
const genReducer = (types, initState) => {
  const jsReducer = customizeReducer(types);
  return createReducer(initState, jsReducer);
};

export default genReducer;
