import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PostItem from "../../../components/PostItem";
import postActions from "../../../model/posts/actions";
import postViewsActions from "../../../model/post_views/actions";
import Grid from "@material-ui/core/Grid";
import postsSelectors, { getPostsByPath } from "../../../model/posts/selectors";
import { getCategoryIdsByPath } from "../../../model/categories/selectors";
import { isEqual, isEmpty } from "lodash";
import queryString from "query-string";
import { getTag } from "../../../model/\btags/selectors";
import { Typography } from "@material-ui/core";

class Posts extends Component {
  componentDidMount() {
    const { fetchPosts, categories, posts, tagId } = this.props;
    if (categories && isEmpty(posts)) {
      fetchPosts({ categories, tagId, limit: 100 });
    }
  }

  componentDidUpdate(prevProps) {
    const { categories, tagId, fetchPosts } = this.props;
    if (!isEqual(categories, prevProps.categories)) {
      fetchPosts({ categories, tagId, limit: 100 });
    }
  }

  handleClick = id => {
    const { updatePost } = this.props;
    updatePost(id);
  };

  render() {
    const { posts, isLoading } = this.props;
    const isEmpty = !posts || posts.length < 1;
    return (
      <Grid container>
        {!isEmpty &&
          posts.map(post => (
            <PostItem key={post.id} post={post} onClick={this.handleClick} />
          ))}
        {isEmpty && !isLoading && (
          <Typography
            style={{
              textAlign: "center",
              fontSize: 18,
              width: "100%",
            }}
          >
            Không tìm thấy bài viết nào
          </Typography>
        )}
      </Grid>
    );
  }
}

Posts.defaultProps = {
  isLoading: false,
  categoryIds: null,
  tagId: null,
};

Posts.propTypes = {
  posts: PropTypes.arrayOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  tagId: PropTypes.number,
  isLoading: PropTypes.bool,
  categories: PropTypes.arrayOf(Object),
  fetchPosts: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const search = queryString.parse(props.location.search);
  const tagId = getTag(state, { slug: search.tag });
  return {
    isLoading: postsSelectors.isLoading(state),
    posts: getPostsByPath(state, { ...props, tagId }),
    categories: getCategoryIdsByPath(state, props),
    tagId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPosts: data => dispatch(postActions.get(null, data)),
  updatePost: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Posts);
