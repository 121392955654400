import React, { PureComponent } from "react";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import { Layout, Color } from "../../../constants";
import Search from "./Search";

const styles = {
  root: {
    height: 45,
    background: Color.PRIMARY,
    justifyContent: 'center'
  },
  content: {
    maxWidth: Layout.MAX_WIDTH,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  icon: {
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: 26
  }
}

class Header extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item container className={classes.content}>
          <Search id='search' />
          <PersonIcon className={classes.icon} />
        </Grid>
      </Grid>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
}

export default withStyles(styles)(Header);