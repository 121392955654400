import Types from "./types";

export const getHomePostsRequest = (id, categories) => ({
    type: Types.GET_HOME_POSTS_REQUEST,
    id,
    categories
});

export const getHomePostsSuccess = (id, data) => ({
    type: Types.GET_HOME_POSTS_SUCCESS,
    id,
    data
});

export const getHomePostsFailed = (id, error) => ({
    type: Types.GET_HOME_POSTS_REQUEST,
    id,
    error
});

export const searchPostsRequest = (id, value) => ({
    type: Types.SEARCH_POSTS_REQUEST,
    id,
    value
});

export const searchPostsSuccess = (id, data) => ({
    type: Types.SEARCH_POSTS_SUCCESS,
    id,
    data
});

export const searchPostsFailed = (id, error) => ({
    type: Types.SEARCH_POSTS_FAILED,
    id,
    error
});

export default {};