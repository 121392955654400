import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Activity from '../activity'
import Modules from '../../../components/Modules';
import Post from './Post';
import Table from '../../table';
import Service from '../service'

const styles = {
  modules: {
    marginTop: 10,
    borderRadius: 0
  },
  table: {
    marginTop: 10
  }
}

class Invoice extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Post />
        <Activity />
        <Modules classes={{ root: classes.modules }} />
        <Table classes={{ root: classes.table }} />
        <Service />
      </Grid>
    )
  }
}

Invoice.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
}

export default withStyles(styles)(Invoice);