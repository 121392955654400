import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getSelectedMenuItem } from '../apps/pc/menu/selectors';
import { Images } from '../constants';

const styles = {
  root: {
    background: '#ffffff',
    borderRadius: 4,
    paddingBottom: 5,
    paddingRight: 5
  },
  img: {
    height: 81,
    backgroundSize: '100% 100% !important',
    margin: '5px 0px 0px 5px',
    maxWidth: 'calc(100% - 5px)'
  },
}

const modules = [
  {
    id: 'qhcd',
    img: Images.QUAN_HE_CO_DONG,
    path: '#module',
    isLocal: true
  },
  {
    id: 'ap_luc_luu_luong',
    img: Images.AP_LUC_LUU_LUONG,
    path: 'http://115.75.217.167:8802',
    isLocal: false
  },
  {
    id: 'thanh_toan',
    img: Images.THANH_TOAN_NGAN_HANG,
    path: '/san-pham-va-dich-vu/cac-hinh-thuc-thanh-toan',
    isLocal: true
  },
  {
    id: 'chat_luong_nuoc',
    img: Images.KET_QUA_MAU_NUOC,
    path: '/ket-qua-mau-nuoc',
    isLocal: true
  }
]

class Modules extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        {
          modules.map(item => {
            const { id, path, isLocal, img } = item;
            let chilren = <div className={classes.img} style={{ background: `url(${img}) no-repeat` }} />;
            if (path && !isLocal) {
              chilren = (
                <a href={item.path} rel="noopener noreferrer">
                  <div className={classes.img} style={{ background: `url(${img}) no-repeat` }} />
                </a>
              )
            }
            if (path && isLocal) {
              chilren = (
                <Link to={path} >
                  <div className={classes.img} style={{ background: `url(${img}) no-repeat` }} />
                </Link>
              )
            }
            return (
              <Grid key={id} id={id} item xs={6}>
                {chilren}
              </Grid>
            )

          })
        }
      </Grid>
    )
  }
}

Modules.defaultProps = {
  selectedId: ''
}

Modules.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  selectedId: getSelectedMenuItem(state)
})

export default connect(mapStateToProps)(withStyles(styles)(Modules));