import { isEmpty } from "lodash";
import request from "../../services/request";

export const getMedia = async (limit = 100) =>
  request.get(`/wp-json/wp/v2/media?per_page=${limit}`).then(res => {
    const { data } = res;
    if (isEmpty(data)) {
      return {};
    }
    return data.reduce((acc, item) => {
      if (item) {
        acc[item.id] = item;
      }
      return acc;
    }, {});
  });

export default {};
