import store from '../store';
import mediaAction from '../model/media/actions';
import { getMedia } from '../model/media/services';

const mediaTask = () => getMedia().then((media) => {
  store.dispatch(mediaAction.storeAll(media));
  return media;
});

export default mediaTask;
