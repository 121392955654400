import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { EMPTY } from "../../../constants";

class PostTitle extends React.Component {
  render() {
    const { classes, post } = this.props;
    return (
      <Link to={`/${post.path}`}>
        <Paper square elevation={0} className={classes.header}>
          <Typography noWrap className={classes.text}>
            {post.title.rendered || EMPTY}
          </Typography>
        </Paper>
      </Link>
    );
  }
}

PostTitle.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
};

export default PostTitle;
