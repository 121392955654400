import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PostSlider from "./PostSlider";
import Popular from "./Popular";
import { getPostNews } from "../../../model/posts/selectors";

const styles = {
  root: {
    background: "#ffffff",
    padding: 5,
  },
  content: {
    paddingTop: 10,
  },
  img: {
    border: "1px solid #dfdfdf",
    width: 100,
    height: 60,
    backgroundSize: "contain !important",
    backgroundPosition: "center !important",
  },
  popular: {
    color: "#337ab7",
    height: 70,
    "&:hover": {
      color: "#23527c",
    },
  },
  title: {
    lineHeight: 1.4,
    fontSize: 14,
    color: "#000000",
    fontWeight: "normal",
  },
};

class News extends React.Component {
  render() {
    const { classes, posts } = this.props;
    return (
      <Grid container className={classes.root}>
        <PostSlider posts={posts} />
        <Grid item container className={classes.content}>
          {posts.map(post => {
            return <Popular key={post.id} classes={classes} post={post} />;
          })}
        </Grid>
      </Grid>
    );
  }
}

News.defaultProps = {
  posts: [],
};

News.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  posts: PropTypes.arrayOf(Object),
};

const mapStateToProps = state => ({
  posts: getPostNews(state),
});

export default connect(mapStateToProps)(withStyles(styles)(News));
