import { all } from "redux-saga/effects";
import postsSagaModel from "./model/posts/saga";
import userSagaModel from "./model/user/saga";
import categoriesSagaModel from "./model/categories/saga";
import mediaSagaModel from "./model/media/saga";
import acfSagaModel from "./model/acf/saga";
import visitorsSagaModel from "./model/visitors/saga";
import contactSagaModel from "./model/contact/saga";
import postViewsSagaModel from "./model/post_views/saga";
import tagsSagaModel from "./model/tags/saga";
import taskSaga from "./sagas/taskSaga";
import postsSaga from "./reducers/posts/saga";

export default function* root() {
  yield all([
    postsSagaModel(),
    userSagaModel(),
    categoriesSagaModel(),
    visitorsSagaModel(),
    mediaSagaModel(),
    contactSagaModel(),
    postViewsSagaModel(),
    taskSaga(),
    postsSaga(),
    tagsSagaModel(),
    acfSagaModel(),
  ]);
}
