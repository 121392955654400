import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import PopularPost from "../../../components/PopularPost";

class Popular extends React.PureComponent {
  render() {
    const { classes, post } = this.props;
    return (
      <Grid item container>
        <PopularPost
          post={post}
          classes={{
            root: classes.popular,
            img: classes.img,
            title: classes.title,
          }}
        />
      </Grid>
    );
  }
}

Popular.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
};

export default Popular;
