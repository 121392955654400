import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { getMediaByKey } from "../utils/media";
import { Color, EMPTY, Images } from "../constants";

const styles = {
  root: {
    padding: "8px 0px",
    width: "100%",
    display: "inline-flex",
    height: 63,
    color: "#171717",
    lineHeight: 1.5,
    "&:hover": {
      color: Color.PRIMARY,
    },
  },
  img: {
    width: 150,
    height: 150,
    marginRight: 10,
    border: "1px solid #dfdfdf",
  },
  title: {
    width: "calc(100% - 80px)",
    fontSize: 15,
    fontWeight: "bold",
    color: "#337ab7",
    "&:hover": {
      color: "#23527c",
    },
  },
  wrapper: {},
  divider: {},
  popularPost: {},
};

class PopularPost extends React.PureComponent {
  handleClick = () => {
    const { onClick, post } = this.props;
    if (post && post.id) {
      onClick(post.id);
    }
  };

  render() {
    const { classes, post } = this.props;
    const image = getMediaByKey(post, "medium");
    let path = post.path;
    if(path && path !== '/') {
      path = `/${post.path}/${post.slug}`;
    } else {
      path = `/${post.slug}`;
    }
    return (
      <Link
        to={path}
        className={classes.root}
        onClick={this.handleClick}
      >
        <img
          className={classes.img}
          src={image ? image.source_url : Images.AVARTA_POST}
          alt=""
        />
        <Typography className={classes.title}>
          {post ? post.title.rendered : EMPTY}
        </Typography>
      </Link>
    );
  }
}

PopularPost.defaultProps = {
  onClick: () => {},
};

PopularPost.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(PopularPost);
