import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import PanelTitle from "../../../components/PanelTitle";
import PopularPost from "../../../components/PopularPost";
import { makeGetPostsByCatPk } from "../../../model/posts/selectors";

class Posts extends PureComponent {
  render() {
    const { classes, posts, title, path } = this.props;
    const len = posts ? posts.length - 1 : 0;
    return (
      <Grid item container className={classes.wrapper}>
        <PanelTitle title={title} />
        <Grid container className={classes.popularPost}>
          {posts.map((post, index) => (
            <Fragment key={post.id}>
              <PopularPost classes={classes} post={post} path={path} />
              {index < len && <Divider className={classes.divider} />}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    );
  }
}

Posts.defaultProps = {
  posts: [],
};

Posts.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(Object),
};

const makeMapStateToProps = () => {
  const getPosts = makeGetPostsByCatPk();
  const mapStateToProps = (state, props) => ({
    posts: getPosts(state, props),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(Posts);
