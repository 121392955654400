import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import PropTypes from "prop-types";
import PostItem from "./PostItem";
import PostTitle from "./PostTitle";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 10,
    position: "relative",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    backgroundColor: "#0000009e",
    position: "absolute",
    left: 0,
    bottom: 10,
    color: "#ffffff",
    width: "calc(100% - 20px)",
    zIndex: 1,
    margin: "0px 10px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #dfdfdf",
  },
  img: {
    height: 308,
    display: "block",
    overflow: "hidden",
    width: "unset",
  },
  text: {
    fontWeight: "bold",
    padding: "0px 10px",
  },
}));

function PostSlider({ posts, updatePost }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const isEmpty = !posts || (posts && posts.length < 1);
  const post = posts[activeStep];

  return (
    <div className={classes.root}>
      {post && <PostTitle classes={classes} post={post} />}
      {!isEmpty && (
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {posts.map((post, index) => {
            return (
              <PostItem
                key={post.id}
                classes={classes}
                activeStep={activeStep}
                index={index}
                post={post}
                updatePost={updatePost}
              />
            );
          })}
        </AutoPlaySwipeableViews>
      )}
    </div>
  );
}

PostSlider.defaultProps = {
  posts: [],
};

PostSlider.propTypes = {
  posts: PropTypes.arrayOf(Object),
  updatePost: PropTypes.func.isRequired,
};

export default PostSlider;
