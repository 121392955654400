import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { CategoryPks } from '../constants';
import { getLastPath, getFirstPath } from './url';

export const getCatPk = location => CategoryPks[getLastPath(location)];

export const getParentCatPk = location => CategoryPks[getFirstPath(location)];

export const getCatPathByPost = post => {
  if (isEmpty(post)) {
    return null;
  }
  let { categories } = post;
  categories = sortBy(categories, 'pk');
  const path = categories.map(cat => cat.path).join('/');
  return path;
};

export default {};
