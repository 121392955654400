import store from "../store";
import postViewsActions from "../model/post_views/actions";
import { getPostViews } from "../model/post_views/services";

const postViewsTask = () =>
  getPostViews().then(postViews => {
    store.dispatch(postViewsActions.storeAll(postViews));
    return postViews;
  });

export default postViewsTask;
