export const AlertTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const AlertOptions = {
  success: {
    variant: AlertTypes.SUCCESS,
    autoHideDuration: 10000,
    preventDuplicate: true
  },
  info: {
    variant: AlertTypes.INFO,
    autoHideDuration: 10000,
    preventDuplicate: true
  },
  warning: {
    variant: AlertTypes.WARNING,
    autoHideDuration: 10000,
    preventDuplicate: true
  },
  error: {
    variant: AlertTypes.ERROR,
    autoHideDuration: 10000,
    preventDuplicate: true
  }
};

export default {};