import request from "../../services/request";

//exclude=1,34,35
export const getCategories = async () =>
  request
    .get(`/wp-json/wp/v2/categories?per_page=40&orderby=id`)
    .then(res => res.data);

export const createCategory = async data =>
  request.post("/api/admin/v1/cat/create_cat", data).then(res => res.data);

export const updateCategory = async data =>
  request.put("/api/admin/v1/cat/update_cat", data).then(res => res.data);

export const deleteCategory = async catPk =>
  request
    .delete(`/api/admin/v1/cat/delete_cats/${catPk}`)
    .then(res => res.data);

export default {};
