import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PanelTitle from "../../../components/PanelTitle";
import PopularPost from "../../../components/PopularPost";
import { CategoryPks } from "../../../constants";
import { makeGetPostsByCatPk } from "../../../model/posts/selectors";
import postViewsActions from "../../../model/post_views/actions";

const styles = {
  root: {
    marginTop: 10,
  },
  wrapper: {
    marginBottom: 12,
    boxShadow: "0 1px 2px rgba(0,0,0,.05)",
  },
  popularPost: {
    overflowY: "auto",
    border: "1px solid #3498db",
    borderTop: 0,
    padding: 5,
    background: "#ffffff",
  },
  divider: {
    width: "100%",
  },
  corner: {
    width: 30,
    "&:after": {
      borderWidth: "28px 18px",
    },
  },
  img: {
    width: 70,
    height: 47,
  },
  title: {
    fontSize: 14,
    fontWeight: "normal",
    color: "#000000",
  },
};

class Service extends PureComponent {
  render() {
    const { classes, posts, updatePostView } = this.props;
    const len = posts ? posts.length - 1 : 0;
    return (
      <Grid container className={classes.root}>
        <PanelTitle
          title="SẢN PHẨM DỊCH VỤ"
          classes={{ corner: classes.corner }}
        />
        <Grid container className={classes.popularPost}>
          {posts.map((post, index) => (
            <Fragment key={post.id}>
              <PopularPost
                classes={{ img: classes.img, title: classes.title }}
                post={post}
                onClick={updatePostView}
              />
              {index < len && <Divider className={classes.divider} />}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    );
  }
}

Service.defaultProps = {
  posts: [],
};

Service.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  posts: PropTypes.arrayOf(Object),
  updatePostView: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
  const getPosts = makeGetPostsByCatPk();
  const mapStateToProps = (state, props) => ({
    posts: getPosts(state, {
      ...props,
      id: CategoryPks["san-pham-va-dich-vu"],
    }),
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  updatePostView: id => dispatch(postViewsActions.create(id)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Service));
