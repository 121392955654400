const styles = theme => ({
  well: {
    minHeight: 20,
    padding: 10,
    marginBottom: 20,
    backgroundColor: '#ffffff',
    border: '1px solid #e3e3e3',
    borderRadius: 4,
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
    fontSize: 16
  },
  panel: {
    marginBottom: 20,
    backgroundColor: '#ffffff',
    border: '1px solid #dddddd',
    borderRadius: 4,
    boxShadow: '0 1px 1px rgba(0,0,0,.05)',
  },
  panelHeading: {
    width: '100%',
    borderBottom: '1px solid transparent',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px 4px 0px 0px',
    padding: 10,
    fontSize: 16,
    fontWeight: 700
  },
  panelContent: {
    padding: 10
  },
  text: {
    width: '100%',
    fontSize: 15
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: 18,
    marginRight: 8,
    color: '#575757'
  },
  description: {
    marginBottom: 10,
    fontSize: 15
  },
  feeback: {
    backgroundColor: '#0081dd',
    borderColor: '#0081dd',
    color: '#ffffff',
    marginBottom: 8
  },
  textField: {
    marginBottom: 16
  },
  action: {
    paddingBottom: 15
  },
  button: {
    textTransform: 'none',
    marginRight: 15,
    fontSize: 13
  },
  img: {
    width: 505,
    height: 293,
    background: 'url(http://capnuocthotnot.vn//assets/images/uploads/maps.png) no-repeat',
    backgroundSize: '100% 100% !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  captcha: {
    marginTop: 15,
    marginBottom: 15
  }
});

export default styles;