import React from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TreeView from './TreeView';
import Typography from '@material-ui/core/Typography';
import { getTreeData } from '../../../model/categories/selectors';

class LeftMenu extends React.Component {
  render() {
    const { classes, open, onClose, treeData } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={onClose}
      >
        <Link to="/" onClick={onClose}>
          <Typography className={classes.home}>
            <HomeIcon className={classes.homeIcon} />
            <span>Trang chủ</span>
          </Typography>
        </Link>
        <TreeView onClose={onClose} treeData={treeData} />
      </Drawer>
    )
  }
}

LeftMenu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  treeData: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  treeData: getTreeData(state)
});

export default connect(mapStateToProps)(LeftMenu);