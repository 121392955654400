import request from "../../services/request";
import Cookies from "js-cookie";

export const getAdminUser = () => {
  return request
    .get("/api/admin/user")
    .then(res => ({ [res.data.pk]: res.data }));
};

export const loginAdmin = ({ username, password }) => {
  return request
    .post("/wp-json/jwt-auth/v1/token", { username, password })
    .then(res => {
      const { data } = res;
      Cookies.set("api_token", data.token);
      return data;
    });
};

export const logoutAdmin = email => {
  return request.post("/api/admin/logout", { email }).then(res => {
    Cookies.set("api_token", "");
    Cookies.set("pk", "");
    return res.data;
  });
};

export default {};
