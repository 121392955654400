import genSaga from '../core/saga';
import types from './types';
import actions from './actions';
import { getMedia } from './services';

export default genSaga(
  types,
  {
    get: getMedia
  },
  actions
);
