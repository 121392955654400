import { isEmpty } from "lodash";
import request from "../../services/request";

export const getTags = async (post, limit = 100) => {
  const url = post
    ? `/wp-json/wp/v2/tags?per_page=${limit}&post=${post}`
    : `/wp-json/wp/v2/tags?per_page=${limit}`;
  return request.get(url).then(res => {
    const { data } = res;
    if (isEmpty(data)) {
      return {};
    }
    return data.reduce((acc, item) => {
      if (item) {
        acc[item.id] = item;
      }
      return acc;
    }, {});
  });
};

export default {};
