import { format, getDay } from "date-fns";

const DayOfWeek = {
  0: 'Chủ nhật',
  1: 'Thứ hai',
  2: 'Thứ ba',
  3: 'Thứ tư',
  4: 'Thứ năm',
  5: 'Thứ sáu',
  6: 'Thứ bảy'
}

const FORMAT_PATTERN = 'dd/MM/yyyy HH:mm:ss';

export const formatDate = (date = new Date(), isDay = false, pattern = FORMAT_PATTERN) => {
  let strToDate =  date;
  if(typeof date === 'string') {
    strToDate = new Date(date);
  }
  const result = format(strToDate, pattern)
    if(isDay) {
      const dayOfWeek = getDay(strToDate);
      return `${DayOfWeek[dayOfWeek]} - ${result}`;
    }
    return result;
}

export default {};