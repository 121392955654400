import React, { PureComponent } from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { EMPTY } from "../constants";

class LinkPost extends PureComponent {
  handleClick = () => {
    const { onClick, post } = this.props;
    if (post.id) {
      onClick(post.id);
    }
  };

  render() {
    const { classes, post } = this.props;
    let path = post.path;
    if(path && path !== '/') {
      path = `/${post.path}/${post.slug}`;
    } else {
      path = `/${post.slug}`;
    }
    return (
      <Link
        to={path}
        className={classes.link}
        onClick={this.handleClick}
      >
        <Typography component="div" className={classes.item}>
          <ArrowRightIcon className={classes.icon} />
          <Typography noWrap className={classes.text}>
            {post.title.rendered || EMPTY}
          </Typography>
        </Typography>
      </Link>
    );
  }
}

LinkPost.propTypes = {
  post: {},
  onClick: () => {},
};

LinkPost.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  post: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};

export default LinkPost;
