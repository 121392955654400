import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import { List } from "immutable";
import genSelectors from "../core/selectors";
import { MODEL } from "../core/reducer";
import { CATEGORIES_MODEL } from "./reducer";
import { createSelector } from "reselect";
import { isMapEmpty, isListEmpty } from "../../utils/empty";
import { EMPTY, RoutePaths } from "../../constants";
import { getLastPath, getMiddlePath } from "../../utils/url";

const EXCLUDE = [1, 34, 35];

const ROOT_ITEM = "rootId";

const selectors = genSelectors([MODEL, CATEGORIES_MODEL]);

const getPath = (_, props) => props.location || EMPTY;

const getCatsByPost = (_, props) => props.post.categories;

const getImCategories = createSelector(
  selectors.getObjs,
  imCategories => {
    if (isListEmpty(imCategories)) {
      return List();
    }
    return imCategories.filter(
      category => category && !EXCLUDE.includes(category.get("id"))
    );
  }
);

export const getCategoriesInAdmin = createSelector(
  getImCategories,
  categories => {
    if (isMapEmpty(categories)) {
      return [];
    }
    return sortBy(categories.toList().toJS(), "id");
  }
);

export const getTreeData = createSelector(
  getImCategories,
  categories => {
    if (isListEmpty(categories)) {
      return { rootId: ROOT_ITEM, items: {} };
    }
    const cats = categories.filter(cat => !cat.get("parent"));
    const rootChildren = [];
    const items = {};
    cats.forEach(cat => {
      if (!isMapEmpty(cat)) {
        const id = cat.get("id");
        const path = `/${cat.get("slug")}`;

        const childCats = categories.filter(
          childCat => childCat.get("parent") === id
        );
        const children = [];
        if (!isListEmpty(childCats)) {
          childCats.forEach(childCat => {
            const pk = childCat.get("id");
            items[pk] = {
              id: pk,
              name: childCat.get("name"),
              path: `${path}/${childCat.get("slug")}`,
              hasChildren: false,
              isExpanded: false,
              isChildrenLoading: false,
            };
            children.push(pk);
          });
        }
        items[id] = {
          id,
          name: cat.get("name"),
          children,
          path,
          hasChildren: !isEmpty(children),
          isExpanded: false,
          isChildrenLoading: false,
        };
        rootChildren.push(id);
      }
    });
    items[ROOT_ITEM] = {
      id: "rootId",
      children: sortBy(rootChildren),
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      name: "root",
      path: "/root",
    };
    return { rootId: ROOT_ITEM, items };
  }
);

export const makeGetCategoryPath = () =>
  createSelector(
    [getImCategories, getCatsByPost],
    (categories, cats) => {
      if (!Array.isArray(cats) || isEmpty(cats) || isMapEmpty(categories)) {
        return RoutePaths.TIN_TUC;
      }
      const sortCats = sortBy(cats, "id");
      if (sortCats.length === 1) {
        const cat = sortCats[0];
        if (!cat.parent_id) {
          return cat.path;
        }
        const parentPath = categories.getIn([`${cat.parent_id}`, "path"]);
        return `${parentPath}/${cat.path}`;
      }
      return sortCats.map(cat => cat.path).join("/");
    }
  );

export const getCategories = createSelector(
  getImCategories,
  categories => {
    if (isListEmpty(categories)) {
      return [];
    }
    const cats = categories.filter(cat => !cat.get("parent"));
    let data = List();
    cats.forEach(cat => {
      if (!isMapEmpty(cat)) {
        const pk = cat.get("id");
        const path = `/${cat.get("slug")}`;
        const children = categories
          .filter(childCat => childCat.get("parent") === pk)
          .map(child => child.set("path", `${path}/${child.get("slug")}`));
        const newCat = cat.set("children", children).set("path", path);
        data = data.push(newCat);
      }
    });
    return data.toJS();
  }
);

const groupCategoriesByKey = (categories, key = "id") => {
  if (isListEmpty(categories)) {
    return {};
  }
  const arrCategories = categories.toJS();
  return arrCategories.reduce((acc, category) => {
    if (category) {
      acc[category[key]] = category;
    }
    return acc;
  }, {});
};

export const groupCategoriesById = createSelector(
  getImCategories,
  categories => groupCategoriesByKey(categories)
);

export const groupCategoriesBySlug = createSelector(
  selectors.getObjs,
  categories => groupCategoriesByKey(categories, "slug")
);

const getCategoryId = (categories, slug) => {
  const category = categories[slug];
  if (!category) {
    return EMPTY;
  }
  return category.id;
};

export const getCategoryIdsByPath = createSelector(
  [groupCategoriesBySlug, getPath],
  (categories, path) => {
    if (isEmpty(categories)) {
      return null;
    }
    const slug = getLastPath(path);
    const id = getCategoryId(categories, slug);
    return id ? [id] : null;
  }
);

export const getCategoryIdsByPost = createSelector(
  [groupCategoriesBySlug, getPath],
  (categories, path) => {
    if (isEmpty(categories)) {
      return null;
    }
    const slug = getMiddlePath(path);
    const id = getCategoryId(categories, slug);
    return id ? [id] : null;
  }
);

export const isCategory = (state, props) => {
  const categories = groupCategoriesBySlug(state);
  const slug = get(props, "match.params.slug");
  return !slug || !!categories[slug];
};

export default selectors;
